import { AxiosResponse } from 'axios';
import { isArray, isEmpty } from 'lodash';
import { RejectWarningFieldsProps } from 'store/types';
import { Countries, TRADING_PLATFORM_ICON } from 'constant/createAccount';
import { TradingPlatform } from 'type/common';
import { Link } from '@mui/material';
import { TFunction } from 'react-i18next';
import { ResponseFieldsProps } from 'type/form';

interface AnyProps {
  [key: string]: unknown;
}

interface WarningMessageObject {
  warning_message: string;
  warning_value: string;
}

interface UploadFileProps{
  document_id: number;
  type: string;
  warning_message: string[];
  warning_value: string;
}

interface sharehoderProps{
  shareholder_id: number;
  documents: UploadFileProps[];
}

interface directorProps{
  director_id: number;
  documents: UploadFileProps[];
}

export interface RejectWarningUploadOneByOne {
  company?:{
    documents: UploadFileProps[];
  };
  contactPerson?:{
    documents: UploadFileProps[];
  };
  shareholders?: sharehoderProps[];
  directors?: directorProps[];
  primary_account_holder?:{
    documents: UploadFileProps[];
  };
  secondary_account_holder?:{
    documents: UploadFileProps[];
  };
}

export const fetchNestedProperty = (obj: AnyProps, id: string): WarningMessageObject | undefined => {
  const parts = id.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let value: any = obj;

  for (const part of parts) {
    if (!value) {
      return undefined;
    }
    value = value[part];
  }

  return value;
};

export const getFieldWarningMessage = (
  warningObj: RejectWarningFieldsProps,
  step: string,
  id: string,
  index?: number
):string | string[] => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('status') !== 'Reject') return '';

  if (isEmpty(warningObj)) return '';
  if (isEmpty(warningObj[step])) return '';
  if (isArray(warningObj[step])) {
    let arrIndex = 0;
    if (index) arrIndex = index;
    if (isEmpty(warningObj[step][arrIndex])) return '';
    const value = fetchNestedProperty(warningObj[step][arrIndex], id);
    if (!value) return '';
    if (isEmpty(value)) return '';
    return value.warning_message;
  }
  return '';
};

export const checkFieldIsWarning = (
  warningObj: RejectWarningFieldsProps,
  step: string,
  id: string,
  index?: number
):boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('status') !== 'Reject') return false;
  if (isEmpty(warningObj)) return true;
  if (isEmpty(warningObj[step])) return true;
  if (isArray(warningObj[step])) {
    let arrIndex = 0;
    if (index) arrIndex = index;
    if (isEmpty(warningObj[step][arrIndex])) return true;
    const value = fetchNestedProperty(warningObj[step][arrIndex], id);
    if (!value) return true;
    if (isEmpty(value)) return true;

    return false;
  }
  return false;
};

export const checkFieldIsCorrecting = (
  warningObj: RejectWarningFieldsProps,
  step: string,
  id: string,
  val: string| number | boolean,
  index?: number
):boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('status') !== 'Reject') return false;

  if (isEmpty(warningObj)) return false;
  if (isEmpty(warningObj[step])) return false;
  if (isArray(warningObj[step])) {
    let arrIndex = 0;
    if (index) arrIndex = index;
    if (isEmpty(warningObj[step][arrIndex])) return false;
    const value = fetchNestedProperty(warningObj[step][arrIndex], id);
    if (!value) return false;
    if (isEmpty(value)) return false;
    // remove left and right space

    const newVal = typeof val === 'string' ? val.replace(/^\s+|\s+$/g, '') : val;
    if (value.warning_value === newVal) return false;

    // if (warningObj[step][arrIndex][id].warning_value === val) return false;
    return true;
  }
  return true;
};

export const getUploadWarningMessage = (
  warningObj: RejectWarningUploadOneByOne[],
  section: string,
  id: string,
  userID?: number
):string | string[] => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('status') !== 'Reject') return '';
  if (isEmpty(warningObj)) return '';
  if (isArray(warningObj)) {
    if (isEmpty(warningObj[0][section as keyof RejectWarningUploadOneByOne])) return '';
    let valueArray;
    if (section === 'company' || section === 'contactPerson' || section === 'primary_account_holder' || section === 'secondary_account_holder') {
      valueArray = warningObj[0][section as 'company' || 'contactPerson' || 'primary_account_holder' || 'secondary_account_holder']?.documents;
    } else if (section === 'shareholders') {
      const shareholders = warningObj[0]?.shareholders?.filter((x) => x.shareholder_id === userID);
      if (isEmpty(shareholders)) return '';
      valueArray = shareholders?.[0]?.documents;
    } else {
      const directors = warningObj[0]?.directors?.filter((x) => x.director_id === userID);
      if (isEmpty(directors)) return '';
      valueArray = directors?.[0]?.documents;
    }
    if (!valueArray) return '';
    const value = valueArray?.filter((x: { type: string; }) => x.type === id);
    return isEmpty(value) ? '' : value[0].warning_message;
  }
  return '';
};

export const checkUploadWarning = (
  warningObj: RejectWarningUploadOneByOne[],
  section: string,
  id: string,
  userID?: number
):boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('status') !== 'Reject') return false;
  if (isEmpty(warningObj)) return true;
  if (isArray(warningObj)) {
    if (isEmpty(warningObj[0][section as keyof RejectWarningUploadOneByOne])) return true;
    let valueArray;
    if (section === 'company' || section === 'contactPerson' || section === 'primary_account_holder' || section === 'secondary_account_holder') {
      valueArray = warningObj[0][section as 'company' || 'contactPerson' || 'primary_account_holder' || 'secondary_account_holder']?.documents;
    } else if (section === 'shareholders') {
      const shareholders = warningObj[0]?.shareholders?.filter((x) => x.shareholder_id === userID);
      if (isEmpty(shareholders)) return true;
      valueArray = shareholders?.[0]?.documents;
    } else {
      const directors = warningObj[0]?.directors?.filter((x) => x.director_id === userID);
      if (isEmpty(directors)) return true;
      valueArray = directors?.[0]?.documents;
    }
    if (isEmpty(valueArray)) return true;
    const value = valueArray?.filter((x: { type: string; }) => x.type === id);
    return isEmpty(value);
  }
  return true;
};

export const checkUploadCorrecting = (
  warningObj: RejectWarningUploadOneByOne[],
  section: string,
  id: string,
  val: { filePath: string },
  userID?: number
):boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('status') !== 'Reject') return false;
  if (!val || !val.filePath) return false;
  if (isArray(warningObj)) {
    if (isEmpty(warningObj[0][section as keyof RejectWarningUploadOneByOne])) return false;
    let valueArray;
    if (section === 'company' || section === 'contactPerson' || section === 'primary_account_holder' || section === 'secondary_account_holder') {
      valueArray = warningObj[0][section as 'company' || 'contactPerson' || 'primary_account_holder' || 'secondary_account_holder']?.documents;
    } else if (section === 'shareholders') {
      const shareholders = warningObj[0]?.shareholders?.filter((x) => x.shareholder_id === userID);
      if (isEmpty(shareholders)) return false;
      valueArray = shareholders?.[0]?.documents;
    } else {
      const directors = warningObj[0]?.directors?.filter((x) => x.director_id === userID);
      if (isEmpty(directors)) return false;
      valueArray = directors?.[0]?.documents;
    }
    if (!valueArray) return false;
    const value = valueArray?.filter((x: { type: string; }) => x.type === id);
    if (isEmpty(value)) return false;
    return value[0].warning_value !== val.filePath;
  }
  return false;
};

export const setTradingPlatformOptions = <C, Res extends AxiosResponse, T extends TFunction>(
  country: C,
  res: Res,
  t: T
): ResponseFieldsProps[] => res?.data?.map((item: ResponseFieldsProps) => {
    if (item.id === 'trading_platform') {
      item.options.forEach((option) => {
        option.label = option.value;
        option.icon = TRADING_PLATFORM_ICON[option.value as TradingPlatform];
        if (option.value === TradingPlatform.LogixTrader && country !== Countries.AUS) {
          option.tooltip = (
            <>
              <div>{t('logixTraderTooltip', { leverage: 5000 })}</div>
              <Link color="inherit" href={`${item.moreButton?.url}/logixtrader`} target="_blank">{item.moreButton?.name}</Link>
            </>
          );
        }
      });
    }
    return item;
  }) ?? [];
