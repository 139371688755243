export const contactPerson = [
  {
    id: 'ID-Front-C',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence'
  },
  {
    id: 'ID-Back-C',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence'
  },
  {
    id: 'POA-C',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc'
  }
];

export const shareholderData = [
  {
    id: 'ID-Front-S',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence'
  },
  {
    id: 'ID-Back-S',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence'
  },
  {
    id: 'POA-S',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc'
  }
];

export const directorData = [
  {
    id: 'ID-Front-D',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence'
  },
  {
    id: 'ID-Back-D',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence'
  },
  {
    id: 'POA-D',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc'
  }
];

export const contactPersonVFSC = [
  {
    id: 'ID-Front-C',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence'
  },
  {
    id: 'ID-Back-C',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence'
  }
];

export const shareholderDataVFSC = [
  {
    id: 'ID-Front-S',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence'
  },
  {
    id: 'ID-Back-S',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence'
  }
];

export const directorDataVFSC = [
  {
    id: 'ID-Front-D',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence'
  },
  {
    id: 'ID-Back-D',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence'
  }
];

export const companyDocuments = [
  {
    id: 'CI',
    required: true,
    title: 'certificateOfIncorporation',
    fileDescription: 'certificateOfIncorporationDesc'
  },
  {
    id: 'POA-Cor',
    required: true,
    title: 'companyProofOfAddress',
    fileDescription: 'proofOfAddressDesc'
  },
  {
    id: 'RC',
    required: true,
    title: 'regulationCertificates',
    fileDescription: 'regulationCertificatesDesc'
  },
  {
    id: 'SC',
    required: false,
    title: 'shareExtract',
    fileDescription: 'shareExtractDesc'
  }
];
