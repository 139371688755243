/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { getCountryState } from 'api/v1/account';
import { useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import PhoneInput from 'components/FormCells/PhoneInput';
import DatePicker from 'components/DatePicker';
import langData from 'i18n/langs.json';
import Select from 'components/Select';
import { useMediaQuery } from 'react-responsive';
import { device } from 'styles/deviceStyle';
import { isArray, get } from 'lodash';
import {
  getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting, fetchNestedProperty
} from 'containers/CreateAccountForm/utils';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import { useSearchParams } from 'react-router-dom';
import Icon from 'components/Icons';
import Tooltip from '@mui/material/Tooltip';
import Input from 'components/Input';
import { Countries } from 'constant/createAccount';
import { appDayjs } from 'utils/appDayjs.util';
import { StyledFieldWrap, StyledSharePercentage, StyledSharePercentageTitle } from './style';

interface OptionsProps {
  value: string;
  label: string;
}

interface InputInfoProps {
  type: string;
  name: string;
  id: string;
  placeHolder: string;
  options?: OptionsProps[];
  rules?: {
    pattern?: { value: string, message: string };
    maxLength?: { value: number, message: string };
    required?: { value: boolean, message: string }
  };
  errMsg?: {
    required?: string;
    pattern?: string;
  };
}

interface SelectHandlerProps {
  onChange: ((...event: unknown[]) => void) | (() => void);
  prev: string;
  next: string;
}

interface WarningField {
  warning_value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const SharePercentage = (): JSX.Element => {
  const isMobile = useMediaQuery({ query: device.mobileMaxWidth });
  const { t } = useTranslation('registrationForm');

  return (
    <StyledSharePercentage>
      <span aria-label="Percentage Symbol">%</span>
      <Tooltip
        arrow
        placement="right"
        enterTouchDelay={0}
        componentsProps={{
          arrow: {
            sx: {
              color: '#3E3E3E'
            }
          },
          tooltip: {
            sx: {
              width: isMobile ? '150px' : '264px',
              fontFamily: 'Noto Sans',
              bgcolor: '#3E3E3E',
              color: '#f4f4f4',
              borderRadius: 1,
              padding: '8px 16px',
              fontSize: 12
            }
          }
        }}
        title={<div aria-label="Share Percentage Info Tooltip Title">{t('sharePercentageTip')}</div>}
        aria-label="Share Percentage Info Tooltip"
      >
        <StyledSharePercentageTitle>
          <Icon name="Info" aria-label="Share Percentage Info Icon" />
        </StyledSharePercentageTitle>
      </Tooltip>
    </StyledSharePercentage>
  );
};

const InputCell = ({ item, index }: { item: InputInfoProps, index: number, setIsLoading?: (val: boolean) => void }): JSX.Element => {
  const {
    type, id, rules, name, placeHolder, options
  } = item;
  const currentStepNum = useAppSelector((state) => state.createAccountState.step);
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const {
    control, formState: { errors }, setValue, getValues, watch
  } = useFormContext();
  const {
    rejectWarningFields, rejectStepStatus, updateRejectStepWarningStatus, steps
  } = useCreateCorporateAccountFlow();
  const currentStep = steps[currentStepNum];

  const [phoneValue, setPhoneValue] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [livingState, setLivingState] = useState<OptionsProps[] | null>(null);
  const isRegulated = getValues('company.regulate_state') === 'Yes';

  const checkFields = (fields: WarningField, path = ''): boolean => {
    for (const key in fields) {
      if (fields[key] && typeof fields[key] === 'object') {
        if ('warning_value' in fields[key]) {
          if (fields[key].warning_value === getValues(`${path}${key}`)) {
            if (!rejectStepStatus[currentStep].isWarning) {
              updateRejectStepWarningStatus(currentStep, true);
            }
            return true; // return true when a match is found
          }
        } else {
          const isWarning = checkFields(fields[key] as WarningField, `${path}${key}.`);
          if (isWarning) return true; // propagate the true result up
        }
      }
    }
    return false; // default return value if no match is found
  };

  const inputChangeHandler = (inputId: string, inputIndex: number): void => {
    if (searchParams.get('status') !== 'Reject') return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value = '' as any;
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep])) {
      value = fetchNestedProperty(rejectWarningFields[currentStep][inputIndex], inputId);
    }
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep]) && value) {
      if (getValues(inputId) === value.warning_value) {
        if (!rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      } else {
        let isWarning = false;
        for (let j = 0; j < rejectWarningFields[currentStep].length; j += 1) {
          isWarning = checkFields(rejectWarningFields[currentStep][j]);
          if (isWarning) break;
        }
        if (!isWarning && rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, false);
        }
      }
    }
  };

  const selectHandler = ({ onChange, prev, next }: SelectHandlerProps): void => {
    if (prev === next) return;

    onChange(next);
    if (/^(shareholders|directors)\.\d\.country$/.test(id)) {
      setValue(id.replace('country', 'state'), null);
      if ([prev, next].includes(Countries.AUS)) setValue(id.replace('country', 'dob'), null);
    }
    if (id === 'company.country_of_company') {
      setValue('company.company_state_province', null);
    }
  };

  const handleClassName = (): string => {
    if (id === 'zip_code' || id.includes('.zip_code')) return 'zip-code';
    if (id === 'state' || id.includes('.state')) return 'state';
    if (id.includes('share_percentage')) return 'share-percentage';

    return '';
  };

  const watchShareholdersAndDirectorsCountry = /^(shareholders|directors)\.\d\.state$/.test(id) ? watch(id.replace('state', 'country')) : undefined;
  const watchCompanyCountry = id === 'company.company_state_province' ? watch('company.country_of_company') : undefined;
  const watchRegulated = id === 'company.regulated_country' || id === 'company.regulated_number' ? watch('company.regulate_state') : undefined;
  const watchCountry = /^(shareholders|directors)\.\d\.dob$/.test(id) ? watch(id.replace('dob', 'country')) : undefined;
  const isAUS = watchCountry === Countries.AUS;

  const updateCountryState = (countryID: string): void => {
    const country = getValues(countryID);
    setLivingState([{ value: 'N/A', label: 'N/A' }]);
    if (!country) return;
    getCountryState(country).then((res) => {
      if (res.status === 200) {
        setLivingState(res.data.options.length === 0 ? [{ value: 'N/A', label: 'N/A' }] : res.data.options);
      }
    });
  };

  const getInputTypeById = (inputID: string): string => {
    if (inputID.includes('share_percentage')) return 'number';
    return 'text';
  };

  useEffect(() => {
    if (/^(shareholders|directors)\.\d\.state$/.test(id)) {
      updateCountryState(id.replace('state', 'country'));
    }
  }, [id, watchShareholdersAndDirectorsCountry]);

  useEffect(() => {
    if (id === 'company.company_state_province') {
      updateCountryState('company.country_of_company');
    }
  }, [id, watchCompanyCountry]);

  useEffect(() => {
    if (id === 'company.regulated_country' || id === 'company.regulated_number') {
      if (getValues('company.regulate_state') === 'Yes') {
        setIsInputDisabled(false);
      }
      if (getValues('company.regulate_state') === 'No' || !getValues('company.regulate_state')) {
        setValue(id, null);
        setIsInputDisabled(true);
      }
    }
    // When finalizing, fields with warnings cannot be freeze
    if (
      (id.includes('shareholders.0') || id.includes('directors.0'))
      && id !== 'shareholders.0.email'
      && id !== 'directors.0.email'
      && id !== 'shareholders.0.share_percentage'
      && searchParams.get('status') !== 'Reject'
    ) {
      setIsInputDisabled(true);
    }

    if (id.includes('shareholders.0') || id.includes('directors.0')) {
      setIsInputDisabled(false);
    }
  }, [id, watchRegulated]);

  return (
    <StyledFieldWrap className={handleClassName()}>
      {type === 'input' && (
        <Controller
          control={control}
          name={id}
          {...rules}
          rules={{
            ...rules,
            required: watchRegulated ? isRegulated : rules?.required,
            pattern: rules?.pattern && {
              value: new RegExp(rules?.pattern.value),
              message: rules?.pattern.message
            }
          }}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <Input
              label={name}
              height="48px"
              placeholder={placeHolder}
              onChange={(val) => {
                onChange(val);
                inputChangeHandler(id, index);
              }}
              type={getInputTypeById(id)}
              addonAfter={id.includes('share_percentage') ? <SharePercentage key={`${item}-${index}`} /> : ''}
              value={value}
              errorMsg={get(errors, id) && (get(errors, id)?.message as unknown as string)}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id, index)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id, index) || isInputDisabled}
              markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value, index)}
              id={id}
            />
          )}
        />
      )}
      {type === 'select' && (
        <Controller
          control={control}
          name={id}
          {...rules}
          rules={{ required: watchRegulated ? isRegulated : rules?.required?.value }}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <Select
              label={name}
              options={
                /^(shareholders|directors)\.\d\.state$|^company\.company_state_province$/.test(id) && livingState ? livingState : options
              }
              inputHeight="48px"
              onChange={(val) => {
                if (/^(shareholders|directors)\.\d\.country$/.test(id) || id === 'company.country_of_company') {
                  selectHandler({ onChange, next: val, prev: value });
                } else {
                  onChange(val);
                }
                inputChangeHandler(id, index);
              }}
              menuPlacement={/^(shareholders|directors)\.\d\.(state|nationality)$/.test(id) ? 'top' : 'bottom'}
              currentValue={value}
              defaultValue={value}
              placeholder={item.placeHolder}
              errorMsg={get(errors, id) && 'Error'}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id, index)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id, index) ? true : isInputDisabled}
              markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value, index)}
              id={id}
            />
          )}
        />
      )}
      {type === 'phone' && (
        <Controller
          control={control}
          name={id}
          {...item.rules}
          rules={{ required: item.rules?.required?.value }}
          render={({
            field: {
              onChange, value
            }
          }) => (
            <PhoneInput
              id={id}
              label={item.name}
              height="48px"
              onChange={(phoneVal: string, info: { [key: string]: string }) => {
                onChange(phoneVal);
                if (phoneVal === info.dialCode) {
                  setValue(id, null);
                  setPhoneValue(info.dialCode);
                }
                inputChangeHandler(id, index);
              }}
              defaultValue={value || phoneValue}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id, index)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id, index) || isInputDisabled}
              markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value, index)}
              localization={langData[i18n.language as keyof typeof langData]}
            />
          )}
        />
      )}
      {type === 'date' && (
        <Controller
          control={control}
          name={id}
          {...item.rules}
          rules={{ required: item.rules?.required?.value }}
          render={({
            field: {
              onChange,
              value
            }
          }) => (
            <DatePicker
              disableFuture
              id={id}
              label={item.name}
              onChange={(val) => {
                onChange(val);
                inputChangeHandler(id, index);
              }}
              defaultValue={value}
              defaultCalendarMonth={appDayjs().subtract(18, 'year')}
              maxDate={appDayjs().subtract(18, 'year')}
              minDate={isAUS ? appDayjs().subtract(85, 'year') : undefined}
              warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id, index)}
              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id, index) || isInputDisabled}
              markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, id, value, index)}
            />
          )}
        />
      )}
      {type === '' && (
        <Input
          id={id}
          label={item.name}
          height="48px"
          placeholder={item.placeHolder}
          warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, id, index)}
          disabled={checkFieldIsWarning(rejectWarningFields, currentStep, id, index)}
        />
      )}
    </StyledFieldWrap>
  );
};

export default InputCell;
