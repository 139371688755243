import { AppDispatch } from 'store';
import { setAccountStatus, setCreateAccountStatus } from 'containers/Pages/slices';
import { CreateAccountSteps } from 'constant/createAccount';
import { AccountStatus, AccountType } from 'type/common';

interface AccountInfo {
  accountStatus: AccountStatus;
  type: AccountType;
}

// In principle, each user can have a maximum of one incomplete/reject/pending account at any given time.
// However, to prevent multiple incomplete/reject/pending accounts in the account list received from the backend,
// we will arrange the order of the header buttons with the following priorities:
// Personal Incomplete > Corporate Incomplete > Joint Incomplete > Personal Reject > Corporate Reject > Joint Reject
const AccountStatusHandler = (
  target: { [key: string]: { [key: string]: string } },
  dispatch:AppDispatch
): AccountInfo => {
  let accountStatus = AccountStatus.noAccount;
  let accountType = AccountType.personal;

  if (target[`${AccountType.personal}_${AccountStatus.Reject}`]) {
    const { accountID } = target[`${AccountType.personal}_${AccountStatus.Reject}`];
    localStorage.setItem('accountID', accountID);
    accountStatus = AccountStatus.Reject;
    accountType = AccountType.personal;
  } else if (target[`${AccountType.corporate}_${AccountStatus.Reject}`]) {
    const { accountID } = target[`${AccountType.corporate}_${AccountStatus.Reject}`];
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: AccountType.corporate } }));
    localStorage.setItem('accountID', accountID);
    accountStatus = AccountStatus.Reject;
    accountType = AccountType.corporate;
  } else if (target[`${AccountType.joint}_${AccountStatus.Reject}`]) {
    const { accountID } = target[`${AccountType.joint}_${AccountStatus.Reject}`];
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: AccountType.joint } }));
    localStorage.setItem('accountID', accountID);
    accountStatus = AccountStatus.Reject;
    accountType = AccountType.joint;
  } else if (target[`${AccountType.personal}_${AccountStatus.Incomplete}`]) {
    const { formStep, accountID } = target[`${AccountType.personal}_${AccountStatus.Incomplete}`];
    dispatch(setCreateAccountStatus({
      createAccountStatus: {
        type: AccountType.personal,
        isOnBoardingMethodSelecting: formStep === CreateAccountSteps.PersonalAccountCreated
      }
    }));
    localStorage.setItem('accountID', accountID);
    accountStatus = AccountStatus.Incomplete;
    accountType = AccountType.personal;
  } else if (target[`${AccountType.corporate}_${AccountStatus.Incomplete}`]) {
    const { accountID } = target[`${AccountType.corporate}_${AccountStatus.Incomplete}`];
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: AccountType.corporate } }));
    localStorage.setItem('accountID', accountID);
    accountStatus = AccountStatus.Incomplete;
    accountType = AccountType.corporate;
  } else if (target[`${AccountType.joint}_${AccountStatus.Incomplete}`]) {
    const { accountID } = target[`${AccountType.joint}_${AccountStatus.Incomplete}`];
    dispatch(setCreateAccountStatus({ createAccountStatus: { type: AccountType.joint } }));
    localStorage.setItem('accountID', accountID);
    accountStatus = AccountStatus.Incomplete;
    accountType = AccountType.joint;
  } else if (
    target[`${AccountType.personal}_${AccountStatus.Pending}`]
    || target[`${AccountType.corporate}_${AccountStatus.Pending}`]
    || target[`${AccountType.joint}_${AccountStatus.Pending}`]
  ) {
    accountStatus = AccountStatus.Pending;
  } else if (
    target[`${AccountType.personal}_${AccountStatus.Resubmitted}`]
    || target[`${AccountType.corporate}_${AccountStatus.Resubmitted}`]
    || target[`${AccountType.joint}_${AccountStatus.Resubmitted}`]
  ) {
    accountStatus = AccountStatus.Resubmitted;
  } else if (
    target[`${AccountType.personal}_${AccountStatus.Approve}`]
    || target[`${AccountType.corporate}_${AccountStatus.Approve}`]
    || target[`${AccountType.joint}_${AccountStatus.Approve}`]
  ) {
    accountStatus = AccountStatus.Approve;
  } else if (
    target[`${AccountType.personal}_${AccountStatus.Active}`]
    || target[`${AccountType.personal}_${AccountStatus.Auto_Active}`]
    || target[`${AccountType.corporate}_${AccountStatus.Active}`]
    || target[`${AccountType.joint}_${AccountStatus.Active}`]
  ) {
    accountStatus = AccountStatus.Active;
  } else if (
    target[`${AccountType.personal}_${AccountStatus.Knockout}`]
    || target[`${AccountType.corporate}_${AccountStatus.Knockout}`]
    || target[`${AccountType.joint}_${AccountStatus.Knockout}`]
  ) {
    accountStatus = AccountStatus.Knockout;
  } else {
    accountStatus = AccountStatus.noAccount;
  }
  dispatch(setAccountStatus({ accountStatus }));
  return { accountStatus, type: accountType };
};

export default AccountStatusHandler;
