/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import FormPanel from 'components/FormPanel';
import UploadFile, { ReturnFiles } from 'components/UploadFileWithAPI';
import { useFormContext, Controller } from 'react-hook-form';
import {
  primaryHolder,
  primaryHolderVFSC,
  secondaryHolder,
  secondaryHolderVFSC
} from 'containers/CreateJointAccountForm/data/registration';
import { useCreateJointAccountFlow } from 'store/context/hooks';
import { CreateJointAccountSteps } from 'constant/createAccount';
import { useTranslation } from 'react-i18next';
import {
  getUploadWarningMessage,
  checkUploadWarning,
  checkUploadCorrecting,
  RejectWarningUploadOneByOne
} from 'containers/CreateAccountForm/utils';
import { useSearchParams } from 'react-router-dom';
import ErrorHandler from 'utils/ErrorHandler';
import { getFileList } from 'api/v1/account';
import { useAppDispatch } from 'store';
import { isEmpty, isArray } from 'lodash';
import { Regulation } from 'type/common';
import {
  StyledConfirmedId,
  StyledUploadWrap,
  StyledSectionTitle,
  StyledIdWrap
} from './style';
import { StyledContentWrap } from '../style';

const initFileList = {
  documents: [
    {
      name: '',
      size: 0,
      document_id: undefined,
      file_path: '',
      type: ''
    }
  ],
  secondary_account_holder: {
    documents: [
      {
        name: '',
        size: 0,
        document_id: undefined,
        file_path: '',
        type: ''
      }
    ]
  }
};

const ConfirmId = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const { control, formState: { errors }, getValues } = useFormContext();
  const currentStep = CreateJointAccountSteps.JointConfirmID;
  const primaryFirstName: string = getValues('first_name');
  const primaryLastName: string = getValues('last_name');
  const secondaryFirstName: string = getValues('secondary_account_holder.first_name');
  const secondaryLastName: string = getValues('secondary_account_holder.last_name');
  const [fileList, setFileList] = useState(initFileList);
  const {
    flow, rejectWarningFields, updateRejectStepWarningStatus
  } = useCreateJointAccountFlow();
  const primaryUpload = flow === Regulation.VFSC ? primaryHolderVFSC : primaryHolder;
  const secondaryUpload = flow === Regulation.VFSC ? secondaryHolderVFSC : secondaryHolder;
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  // waiting for api support
  useEffect(() => {
    setFileList(initFileList);
    const accountID = String(localStorage.getItem('accountID'));
    ErrorHandler(
      getFileList(accountID),
      dispatch
    )
      .then((res) => {
        if (res && res.status === 200) {
          setFileList(res.data);
        }
      });
  }, []);
  const inputChangeHandler = (): void => {
    if (searchParams.get('status') !== 'Reject') return;
    let warning = false;
    if (isArray(rejectWarningFields[currentStep])) {
      const rejection = rejectWarningFields[currentStep][0] as RejectWarningUploadOneByOne;
      Object.keys(rejection).forEach((key) => {
        const rejectArray = rejection[key as 'primary_account_holder' || 'secondary_account_holder']?.documents;
        const currentfiles = key === 'primary_account_holder' ? getValues('documents') : getValues(key)?.documents;
        if (!isEmpty(rejectArray) && !isEmpty(currentfiles)) {
          rejectArray?.forEach((doc: { warning_value: string; type: string}) => {
            const filterData = currentfiles?.filter((x : { type: string }) => x.type === doc.type);
            if (!isEmpty(filterData) && doc.warning_value === filterData[filterData.length - 1].file_path) {
              warning = true;
            }
          });
        }
      });
    }

    updateRejectStepWarningStatus(currentStep, warning);
  };

  return (
    <StyledConfirmedId>
      <FormPanel id="upload-id-documents" title={t('ID Documents')}>
        <StyledContentWrap>
          <StyledIdWrap lastItem={false}>
            <StyledSectionTitle aria-label="Primary Account Holder Title">
              {`${t('primaryAccountHolder')}: ${primaryFirstName} ${primaryLastName}`}
            </StyledSectionTitle>
            <StyledUploadWrap>
              {primaryUpload.map((uploadItem) => {
                const warningMsg = getUploadWarningMessage(rejectWarningFields[currentStep], 'primary_account_holder', uploadItem.id, 0);
                return (
                  <Controller
                    key={`${uploadItem.id}`}
                    control={control}
                    name={`${uploadItem.id}`}
                    rules={{ required: warningMsg || searchParams.get('status') !== 'Reject' ? uploadItem.required : false }}
                    render={({
                      field: { onChange, value }
                    }) => (
                      <UploadFile
                        id={uploadItem.id}
                        onChange={(file: ReturnFiles) => {
                          onChange(file?.info?.file || '');
                          inputChangeHandler();
                        }}
                        title={t(uploadItem.title)}
                        fileDescription={t(uploadItem.fileDescription)}
                        files={fileList.documents}
                        targetID={0}
                        targetName="primaryAccountHolder"
                        errorMsg={errors[uploadItem.id]?.message as unknown as string}
                        warningMsg={warningMsg}
                        disabled={checkUploadWarning(rejectWarningFields[currentStep], 'primary_account_holder', uploadItem.id, 0)}
                        markWarning={checkUploadCorrecting(rejectWarningFields[currentStep], 'primary_account_holder', uploadItem.id, value, 0)}
                        setFileList={setFileList}
                      />
                    )}
                  />
                );
              })}
            </StyledUploadWrap>
          </StyledIdWrap>
          <StyledIdWrap lastItem={false}>
            <StyledSectionTitle aria-label="Secondary Account Holder Title">
              {`${t('secondaryAccountHolder')}: ${secondaryFirstName} ${secondaryLastName}`}
            </StyledSectionTitle>
            <StyledUploadWrap>
              {secondaryUpload.map((uploadItem) => {
                const warningMsg = getUploadWarningMessage(rejectWarningFields[currentStep], 'secondary_account_holder', uploadItem.id, 0);
                return (
                  <Controller
                    key={`${uploadItem.id}`}
                    control={control}
                    name={`${uploadItem.id}`}
                    rules={{ required: warningMsg || searchParams.get('status') !== 'Reject' ? uploadItem.required : false }}
                    render={({
                      field: { onChange, value }
                    }) => (
                      <UploadFile
                        id={uploadItem.id}
                        onChange={(file: ReturnFiles) => {
                          onChange(file?.info?.file || '');
                          inputChangeHandler();
                        }}
                        title={t(uploadItem.title)}
                        fileDescription={t(uploadItem.fileDescription)}
                        files={fileList?.secondary_account_holder?.documents || initFileList.secondary_account_holder.documents}
                        targetID={0}
                        targetName="secondaryAccountHolder"
                        errorMsg={errors[uploadItem.id]?.message as unknown as string}
                        warningMsg={warningMsg}
                        disabled={checkUploadWarning(rejectWarningFields[currentStep], 'secondary_account_holder', uploadItem.id, 0)}
                        markWarning={checkUploadCorrecting(rejectWarningFields[currentStep], 'secondary_account_holder', uploadItem.id, value, 0)}
                        setFileList={setFileList}
                      />
                    )}
                  />
                );
              })}
            </StyledUploadWrap>
          </StyledIdWrap>
        </StyledContentWrap>
      </FormPanel>
    </StyledConfirmedId>
  );
};

export default ConfirmId;
