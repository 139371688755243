/* eslint-disable @typescript-eslint/no-explicit-any */
import IconComponent from 'components/Icons';
import { formatBytes } from 'utils/common';
import Button from '@mui/material/Button';
import {
  useState, useEffect, ChangeEventHandler
} from 'react';
import { useTranslation } from 'react-i18next';
import { uploadDocument, deleteDocument, getFileList } from 'api/v1/account';
import { useAppDispatch } from 'store';
import ErrorHandler from 'utils/ErrorHandler';
import { isArray, isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import {
  StyledUploadFile, StyledStatusImgWrap, StyledFileInfoWrap, StyledFileNameWrap,
  StyledSizeInfo, StyledCloseIcon, StyledStatusBlock, StyledStatusTxt,
  StyledFileDesp, StyledFileTitle, StyledWarningMsg, StyledWarningWrapper
} from './style';

interface FileInfoProps {
  name: string;
  size: number;
  type: string;
  documentID?: number;
  filePath?: string;
}

enum FileExtension {
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  heic = 'heic',
  pdf = 'pdf',
}

enum FileStatus {
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
  INIT = 'INIT',
  DISABLED = 'DISABLED',
  PENDING = 'PENDING'
}

const IconName = {
  [FileStatus.UPLOADED]: 'CloudUploaded',
  [FileStatus.ERROR]: 'CloudToCheck',
  [FileStatus.INIT]: 'CloudToCheck',
  [FileStatus.DISABLED]: 'CloudToCheck',
  [FileStatus.PENDING]: 'CloudToCheck'
};

export interface ReturnFiles {
  info: {
    file?: {
      documentID?: number;
      filePath?: string;
      name: string;
      size: number;
    },
    status: FileStatus
  } | null
}
interface UploadFileProps {
  title: string;
  acceptExtension?: FileExtension[];
  maxSizeMB?: number; // MB
  fileDescription: string;
  onChange: (filesInfo: ReturnFiles) => void;
  onClick?: () => void;
  onClose?: () => void;
  errorMsg?: string;
  disabled?: boolean;
  warningMsg?: string | string[];
  markWarning?: boolean;
  id?:string;
  files:FileList[];
  targetID: number;
  targetName: string;
  setFileList: (arg0:any) =>void;
}

interface FileList {
  shareholder_id?: number;
  director_id?: number;
  type: string;
  name: string;
  size: number;
  document_id?: number | undefined;
  documents?: File [];
  file_path: string;
}

interface File {
  file_path: string;
  type: string;
  name: string;
  size: number;
  document_id: number | undefined;
}

const UploadFile = (props: UploadFileProps) => {
  const { t } = useTranslation('registrationForm');
  const {
    title,
    acceptExtension = [
      FileExtension.jpg,
      FileExtension.jpeg,
      FileExtension.png,
      FileExtension.pdf,
      FileExtension.heic
    ],
    maxSizeMB = 5,
    fileDescription,
    onChange,
    onClick,
    onClose,
    errorMsg,
    disabled,
    warningMsg,
    id = '',
    files,
    targetID,
    targetName,
    markWarning,
    setFileList,
    ...others
  } = props;
  const accept = acceptExtension.map((ext) => `.${ext}`).join(',');
  const { setValue, getValues } = useFormContext();
  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.INIT);
  const [fileInfo, setFileInfo] = useState<FileInfoProps | null>(null);
  const dispatch = useAppDispatch();
  const accountID = String(localStorage.getItem('accountID'));
  const FileStatusLowerCase = fileStatus.toLowerCase();

  const clearFile = () => {
    setFileInfo(null);
    onChange({ info: null });
  };

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    try {
      const extension = e.target.value.split('.').pop()?.toLowerCase() || '';
      const isValidFileType = acceptExtension.some((ext) => ext === extension);
      if (!isValidFileType) return;

      setFileStatus(FileStatus.INIT);
      const file = e.target.files?.[0];
      if (!file) return;

      if (file.size > maxSizeMB * 1024 * 1024) {
        setFileStatus(FileStatus.ERROR);
        console.error('File size is larger than the maximum size');
        return;
      }

      let documentID = fileInfo?.documentID || undefined;
      let filePath = '';
      setFileStatus(FileStatus.PENDING);

      ErrorHandler(
        uploadDocument(accountID, id, file, targetID, documentID),
        dispatch
      )
        .then((res) => {
          if (res && res.status === 200) {
            const {
              company,
              directors,
              shareholders,
              documents,
              secondary_account_holder: secondaryAccountHolder
            } = res.data;
            ErrorHandler(
              getFileList(accountID),
              dispatch
            )
              .then((filesRes) => {
                if (filesRes && filesRes.status === 200) {
                  setFileList(filesRes.data);
                  const {
                    company: listCompany,
                    directors: listDirectors,
                    shareholders: listShareholders,
                    documents: listDocuments,
                    secondary_account_holder: listSecondaryAccountHolder
                  } = filesRes.data;
                  if (documents && documents.length > 0) {
                    documentID = documents[0].document_id;
                    filePath = documents[0].file_path;
                    setValue('documents', listDocuments);
                  } else if (shareholders && shareholders.length > 0) {
                    if (!isEmpty(getValues('shareholders'))) {
                      documentID = shareholders[0].documents[0].document_id;
                      filePath = shareholders[0].documents[0].file_path;
                      listShareholders.forEach((shareholder: FileList, shareholdersIndex: number) => {
                        if (shareholders[0].shareholder_id === shareholder.shareholder_id) {
                          setValue(`shareholders[${shareholdersIndex}][documents]`, shareholder?.documents);
                        }
                      });
                    }
                  } else if (directors && directors.length > 0) {
                    if (!isEmpty(getValues('directors'))) {
                      documentID = directors[0].documents[0].document_id;
                      filePath = directors[0].documents[0].file_path;
                      listDirectors.forEach((director: FileList, directorsIndex: number) => {
                        if (directors[0].director_id === director.director_id) {
                          setValue(`directors[${directorsIndex}][documents]`, director?.documents);
                        }
                      });
                    }
                  } else if (secondaryAccountHolder) {
                    documentID = secondaryAccountHolder.documents[0].document_id;
                    filePath = secondaryAccountHolder.documents[0].file_path;
                    setValue('secondary_account_holder[documents]', listSecondaryAccountHolder.documents);
                  } else {
                    documentID = company.documents[0].document_id;
                    filePath = company.documents[0].file_path;
                    setValue('company[documents]', listCompany.documents);
                  }
                }
                setFileInfo(
                  {
                    name: file.name, size: file.size, documentID, filePath, type: file.type
                  }
                );
                setFileStatus(FileStatus.UPLOADED);
                onChange({
                  info: {
                    file: {
                      documentID,
                      filePath,
                      name: file.name,
                      size: file.size
                    },
                    status: fileStatus
                  }
                });
              });
          }
        });
    } catch (error) {
      onChange({
        info: {
          status: fileStatus
        }
      });
    } finally {
      e.target.value = '';
    }
  };

  const handleOnClose = (): void => {
    if (fileStatus === FileStatus.DISABLED || fileStatus === FileStatus.PENDING) return;
    setFileStatus(FileStatus.PENDING);
    ErrorHandler(
      deleteDocument(accountID, fileInfo?.documentID),
      dispatch
    )
      .then((res) => {
        if (res && res.status === 204) {
          clearFile();
          setFileStatus(FileStatus.INIT);
          ErrorHandler(
            getFileList(accountID),
            dispatch
          )
            .then((listRes) => {
              if (listRes && listRes.status === 200) {
                setFileList(listRes.data);
              }
            });
        }
      });
    if (onClose) onClose();
  };

  useEffect(() => {
    if (isArray(files) && !isEmpty(files)) {
      files.forEach((file) => {
        if (
          targetName === 'id_front'
        || targetName === 'id_back'
        || targetName === 'proof_of_address'
        || targetName === 'other_documents'
        || targetName === 'company'
        || targetName === 'contactPerson'
        || targetName === 'secondaryAccountHolder'
        || targetName === 'primaryAccountHolder') {
          if (file?.type === id) {
            setFileInfo({
              name: file.name,
              size: file.size,
              type: file.type,
              documentID: file.document_id,
              filePath: file.file_path
            });
            onChange({
              info: {
                file: {
                  documentID: file.document_id,
                  filePath: file.file_path,
                  name: file.name,
                  size: file.size
                },
                status: fileStatus
              }
            });
            if (fileStatus === FileStatus.INIT) {
              setFileStatus(FileStatus.UPLOADED);
            }
          }
        } else if (targetID === file.shareholder_id || targetID === file.director_id) {
          file?.documents?.forEach((element) => {
            if (element?.type === id) {
              setFileInfo(
                {
                  name: element.name, size: element.size, type: element.type, documentID: element.document_id, filePath: element.file_path
                }
              );
              onChange({
                info: {
                  file: {
                    documentID: element.document_id,
                    filePath: element.file_path,
                    name: element.name,
                    size: element.size
                  },
                  status: fileStatus
                }
              });
              if (fileStatus === FileStatus.INIT) {
                setFileStatus(FileStatus.UPLOADED);
              }
            }
          });
        }
      });
    }
  }, [files]);

  useEffect(() => {
    if (errorMsg) {
      setFileStatus(FileStatus.ERROR);
    }
  }, [errorMsg, warningMsg, markWarning]);

  useEffect(() => {
    if (!isEmpty(warningMsg) && !markWarning) {
      clearFile();
      setFileStatus(FileStatus.INIT);
    }
  }, [markWarning, files]);

  useEffect(() => {
    if (disabled) setFileStatus(FileStatus.DISABLED);
  }, [warningMsg, disabled]);

  useEffect(() => {
    if (fileStatus === FileStatus.ERROR) {
      clearFile();
    }
  }, [fileStatus]);

  return (
    <div>
      <StyledUploadFile className="lp-upload-file">
        <StyledStatusImgWrap aria-label={`${id} ${FileStatusLowerCase} wrap`}>
          <StyledStatusBlock status={fileStatus}>
            <IconComponent name={IconName[fileStatus]} aria-label={`${id} ${FileStatusLowerCase} icon`} />
            <StyledStatusTxt status={fileStatus} aria-label={`${id} file status`}>
              {t('uploadFile')}
            </StyledStatusTxt>
          </StyledStatusBlock>
          {fileStatus === FileStatus.UPLOADED && fileInfo?.size && (
            <StyledSizeInfo aria-label={`${id} file size`}>
              {t('fileSize')}
              {' '}
              {formatBytes(Number(fileInfo.size))}
            </StyledSizeInfo>
          )}
          {fileStatus !== FileStatus.UPLOADED && (
            <StyledSizeInfo aria-label={`${id} file max size`} status={fileStatus}>
              {t('maxLimit', { size: maxSizeMB })}
            </StyledSizeInfo>
          )}
        </StyledStatusImgWrap>

        <StyledFileInfoWrap>
          <StyledFileTitle aria-label={`${id} file title`}>
            {title}
          </StyledFileTitle>
          <StyledFileDesp aria-label={`${id} file description`}>
            {fileDescription}
          </StyledFileDesp>
          <div>
            <div>
              <Button
                aria-label={`${id} upload button`}
                className="upload-btn"
                onClick={onClick}
                variant="contained"
                component="label"
                disabled={fileStatus === FileStatus.DISABLED || fileStatus === FileStatus.PENDING}
              >
                {fileStatus !== FileStatus.PENDING
                  ? t('chooseFile')
                  : <CircularProgress className="custom-circular-progress" />}
                <input
                  accept={accept}
                  type="file"
                  hidden
                  onChange={onChangeFile}
                  {...others}
                />
              </Button>
            </div>
            {fileInfo && (
            <StyledFileNameWrap aria-label={`${id} file name`}>
              <span>
                {t('fileName')}
                {' '}
                {fileInfo?.name}
              </span>
              <StyledCloseIcon
                onClick={handleOnClose}
                aria-label={`${id} close icon`}
              >
                <IconComponent name="Close" />
              </StyledCloseIcon>
              {errorMsg && (
              <StyledSizeInfo aria-label={`${id} file size`} status="error">
                {errorMsg}
              </StyledSizeInfo>
              )}
            </StyledFileNameWrap>
            )}
          </div>
        </StyledFileInfoWrap>
      </StyledUploadFile>
      {warningMsg && (
        <StyledWarningWrapper markWarning={markWarning}>
          {Array.isArray(warningMsg)
            ? warningMsg.map((el, index) => (
              <StyledWarningMsg key={el} markWarning={markWarning} aria-label={`${id} upload error ${index}`}>
                {el}
              </StyledWarningMsg>
            ))
            : (
              <StyledWarningMsg markWarning={markWarning} aria-label={`${id} upload error`}>
                {warningMsg}
              </StyledWarningMsg>
            )}
        </StyledWarningWrapper>
      )}
    </div>
  );
};

export default UploadFile;
