import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledBlurredPopUpWrap = styled.div`
  font-family: 'Noto Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px 30px 30px;
  width: 626px;
  height: auto;
  background-color: #FFFFFF;
  z-index: 1;
  margin: 0 auto;
  border-radius: 16px;
  border: 1px solid var(--grey-grey-300, #E0E0E0);
  background: var(--primary-contrast-text, #FFF);
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.16), 
              10px 7px 12px 0px rgba(0, 0, 0, 0.14), 
              22px 16px 16px 0px rgba(0, 0, 0, 0.08), 
              40px 28px 19px 0px rgba(0, 0, 0, 0.02), 
              62px 43px 21px 0px rgba(0, 0, 0, 0.00);

  @media ${device.mobileMaxWidth} {
    width: 335px;
    padding: 20px;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #1C1C1C;
    margin-top: 28px;
    margin-bottom: 16px;

    @media ${device.mobileMaxWidth} {
      font-size: 14px;
      line-height: 24px;
      width: 148px;
      margin-top: 22px;
    }
  }
`;

export const StyledLine = styled.hr`
  width: 290px;
  margin: 0 auto;
  border: 0;
  height: 0;
  border-bottom: 1px solid #DDDDDD;
`;

export const StyledBoxImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 180px;
  height: 140px;

  @media ${device.mobileMaxWidth} {
    width: 160px;
    height: 120px;
  }
`;

export const BoxImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledMessageWrap = styled.div`
  margin: 16px auto 0 auto;
  text-align: center;
  width: 300px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #5C5C5C;

  @media ${device.mobileMaxWidth} {
    width: 296px;
  }
`;

export const StyledActionGroup = styled.div`
  margin-top: 24px;
  text-align: center;

  @media ${device.mobileMaxWidth} {
    margin-top: 16px;
      }

  button{
    font-family: 'Noto Sans';
    padding: 8px 16px;
    font-style: normal;
    background: #013b81;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    text-transform: none;
    &:hover, &:active{
       background: #013b81;
    }
    
    @media ${device.mobileMaxWidth} {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const StyleNewBlurredPopUpWrap = styled.div`
  font-family: 'Noto Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  width: 626px;
  height: auto;
  background-color: #FFFFFF;
  z-index: 1;
  margin: 0 auto;
  border-radius: 16px;
  border: 1px solid var(--grey-grey-300, #E0E0E0);
  background: var(--primary-contrast-text, #FFF);
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.16), 
              10px 7px 12px 0px rgba(0, 0, 0, 0.14), 
              22px 16px 16px 0px rgba(0, 0, 0, 0.08), 
              40px 28px 19px 0px rgba(0, 0, 0, 0.02), 
              62px 43px 21px 0px rgba(0, 0, 0, 0.00);

  @media ${device.mobileMaxWidth} {
    width: 335px;
  }
`;

export const StyledBlurredPopUpImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
  }
`;

export const StyledBlurredPopUpContentWrapper = styled.div`
  font-style: normal;
  padding: 32px;
`;

export const StyledBlurredPopUpTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: var(--Neutral-N200, #3E3E3E);
  margin: 0 0 8px 0;
`;

export const StyledBlurredPopUpDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--Neutral-N200, #3E3E3E);
  display: flex;
  flex-direction: column;
`;
