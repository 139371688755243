/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useFormContext, Controller
} from 'react-hook-form';
import {
  isValidEmail, isValidPassword, isValidName, isLenNotOverForty
} from 'utils/common';
import { useState, useEffect, useRef } from 'react';
import Icon from 'components/Icons';
import { getCountryList, sendEmailVerificationCode, getCountryByIP } from 'api/v1/user';
import Select from 'components/Select';
import Button from '@mui/material/Button';
import PhoneInput from 'components/FormCells/PhoneInput';
import { Countries } from 'constant/createAccount';
import axios from 'axios';
import countries from 'i18n-iso-countries';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import Lang from 'utils/language.util';
import { Languages } from 'constant/language';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { WHITE_LABELS } from 'type/common';
import langData from '../../i18n/langs.json';

import {
  StyledTitle,
  StyledForm,
  StyledInput,
  StyledErrorMessage,
  StyledCriteria,
  StyledCriteriaContainer,
  StyledCheckboxContainer,
  StyledCheckbox,
  StyledPasswordContainer,
  StyledCheckWrap,
  StyledLoadingWrap,
  // StyledPopUpBtnWrap,
  StyledNameWrap,
  StyledMobileInputWrap,
  StyledCountrySelectWrap,
  StyledAlreadRegisterWrap,
  StyledPolicyLink
} from './style';

export interface IFormInput {
  country: string;
  firstName: string;
  lastName: string;
  mobile: string | null;
  email: string;
  password: string;
  policy: boolean;
  subscription: boolean;
}

interface CountryData {
  type: string;
  name: string;
  id: string;
  placeHolder: string;
  rules: {
    required: {
      value: boolean, message: string
    }
  },
  options: { value: string; label: string }[]
}
interface CheckEmailFormProps {
  handleContinue: () => void;
  handlePopupErrMessage: (errMessage: string) => void;
  handleMobileCountryCode: (mobileCountryCode: string) => void;
}

const CheckEmailForm = (props: CheckEmailFormProps): JSX.Element => {
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(false);
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validFirstNameLen, setValidFirstNameLen] = useState(true);
  const [validLastNameLen, setValidLastNameLen] = useState(true);
  const { handleContinue, handlePopupErrMessage, handleMobileCountryCode } = props;
  const { t, i18n } = useTranslation('createAccount');

  const [criteria, setCriteria] = useState({
    length: false, lowercase: false, uppercase: false, number: false, specialChar: false
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryList, setCountryList] = useState<CountryData | null>(null);
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('au');
  const ipRef = useRef('');

  const {
    register, formState, watch, control, setValue, getValues
  } = useFormContext();
  const watchPassword = watch('password');
  const watchCountry = watch('country');
  const isUbank = DOMAIN_CONFIG.realm === WHITE_LABELS.ubank;
  const handleValidName = (firstName: string, lastName: string): boolean => {
    let allValid = true;
    setValidFirstName(true);
    setValidLastName(true);
    setValidFirstNameLen(true);
    setValidLastNameLen(true);

    if (isValidName(firstName) && !isLenNotOverForty(firstName)) {
      setValidFirstNameLen(false);
      allValid = false;
    } else if (!isValidName(firstName)) {
      setValidFirstName(false);
      allValid = false;
    }

    if (isValidName(lastName) && !isLenNotOverForty(lastName)) {
      setValidLastNameLen(false);
      allValid = false;
    } else if (!isValidName(lastName)) {
      setValidLastName(false);
      allValid = false;
    }

    return allValid;
  };

  useEffect(() => {
    getCountryList().then((res) => {
      if (res && res.status === 200) {
        setCountryList(res.data);
        setIsLoading(false);
      }
    });
    setValue('policy', true);
    setValue('subscription', true);
    // Autofill third-party data
    const urlParams = new URLSearchParams(window.location.search);
    const firstName = urlParams.get('first_name') || '';
    const lastName = urlParams.get('last_name') || '';
    const email = urlParams.get('email') || '';
    const mobile = urlParams.get('mobile');
    const countryCode = urlParams.get('country_code');
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('email', email);
    if (countryCode && mobile) {
      setValue('mobile', countryCode + mobile);
    }
  }, []);

  useEffect(() => {
    if (countryList && !ipRef.current) {
      const fetchIP = async (): Promise<void> => {
        const res = await axios.get('https://api.ipify.org?format=json');
        getCountryByIP(res.data.ip).then((el) => {
          ipRef.current = el.data.iso_code_alpha3;
          if (countryList.options.some(({ value }: { value: string }) => value === el.data.iso_code_alpha3)) {
            setValue('country', el.data.iso_code_alpha3);
          }
        });
      };
      fetchIP();
    }
  }, [countryList]);

  useEffect(() => {
    if (!getValues('mobile')) setPhoneCountry(countries.alpha3ToAlpha2(watchCountry)?.toLocaleLowerCase() ?? '');
  }, [watchCountry]);

  useEffect(() => {
    setValidPassword(isValidPassword(watchPassword));
    const checkLowercase = /[a-z]/;
    const checkUppercase = /[A-Z]/;
    const checkNumber = /\d/;
    const checkSpecialChar = /[~`!@#$%^&*()_\\\-+={[}\]|:;"'<,>.?/]|]/;
    setCriteria({
      length: watchPassword?.length >= 8 && watchPassword?.length <= 20,
      lowercase: !!watchPassword && checkLowercase.test(watchPassword),
      uppercase: checkUppercase.test(watchPassword),
      number: checkNumber.test(watchPassword),
      specialChar: checkSpecialChar.test(watchPassword)
    });
  }, [watchPassword]);

  const handleClick = (): void => {
    const data = getValues() as IFormInput;
    setValidEmail(isValidEmail(data.email));

    if (!handleValidName(data.firstName, data.lastName)) return;

    if (isValidEmail(data.email) && isValidPassword(data.password)) {
      const userInfo = { email: data.email, firstName: data.firstName, lastName: data.lastName };
      setIsLoading(true);
      sendEmailVerificationCode(userInfo).then((res) => {
        if (res.status === 204) handleContinue();
      }).catch((err) => {
        handlePopupErrMessage(err.response.data.message);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const { isValid, errors } = formState;
  const displayPasswordHandler = (): void => {
    setShowPassword((prev) => !prev);
  };
  return (
    <div>
      {!isLoading ? (
        <>
          <StyledTitle aria-label="Create Account">
            {t('createAccount')}
          </StyledTitle>
          <StyledForm curLang={i18n.language}>
            <div>
              {countryList && (
                <StyledCountrySelectWrap>
                  <label aria-label="Country of Residence">{t('countryResidence')}</label>
                  <Controller
                    control={control}
                    name="country"
                    aria-label="country of residence input"
                    rules={{ required: countryList.rules?.required?.value }}
                    render={({
                      field: {
                        onChange,
                        value
                      }
                    }) => (
                      <Select
                        className="country-select"
                        options={countryList.options}
                        inputHeight="48px"
                        onChange={(val) => (
                          onChange(val)
                        )}
                        currentValue={value}
                        defaultValue={value}
                        placeholder={t('pleaseSelect')}
                        errorMsg={errors.country && 'Error'}
                        id={countryList.id}
                        downIconName="DownArrow"
                      />
                    )}
                  />
                </StyledCountrySelectWrap>
              )}

              <StyledNameWrap curLang={i18n.language}>
                <div>
                  <label aria-label="First Name Title">{t('firstName')}</label>
                  <StyledInput
                    {...register('firstName', {
                      required: true
                    })}
                    placeholder={t('firstNameInput')}
                    aria-label="first name input"
                    valid={validFirstName && validFirstNameLen}
                  />
                  <StyledErrorMessage aria-label="Invalid Firstname">
                    {!validFirstName && <span>{t('validFirstName')}</span>}
                    {!validFirstNameLen && <span>{t('maximumCharactersLimit')}</span>}
                  </StyledErrorMessage>
                </div>

                <div>
                  <label aria-label="Last Name Title">{t('lastName')}</label>
                  <StyledInput
                    {...register('lastName', { required: true })}
                    placeholder={t('lastNameInput')}
                    aria-label="last name input"
                    valid={validLastName && validLastNameLen}
                  />
                  <StyledErrorMessage aria-label="Invalid Lastname">
                    {!validLastName && <span>{t('validLastName')}</span>}
                    {!validLastNameLen && <span>{t('maximumCharactersLimit')}</span>}
                  </StyledErrorMessage>
                </div>
              </StyledNameWrap>
              <StyledMobileInputWrap curLang={i18n.language}>
                <Controller
                  control={control}
                  name="mobile"
                  rules={{ required: true }}
                  render={({
                    field: {
                      onChange, value
                    }
                  }) => (
                    <PhoneInput
                      label={t('phoneNO')}
                      height="48px"
                      className="phone-input"
                      onChange={(phoneVal: string, info: { [key: string]: string }) => {
                        onChange(phoneVal);
                        handleMobileCountryCode(info.dialCode);
                        if (phoneVal === info.dialCode) {
                          setValue('mobile', null);
                          setPhoneValue(info.dialCode);
                        }
                      }}
                      defaultValue={value || phoneValue}
                      country={phoneCountry}
                      id="Mobile Phone"
                      localization={langData[i18n.language as keyof typeof langData]}
                    />
                  )}
                />
              </StyledMobileInputWrap>
              <label aria-label="Email Address Title">{t('emailAddress')}</label>
              <StyledInput
                {...register('email', { required: true })}
                placeholder={t('enterYourEmail')}
                aria-label="register email address input"
                valid={validEmail}
              />
              <StyledErrorMessage aria-label="Invalid Email">
                {!validEmail && <span>{t('invalidEmailAddress')}</span>}
              </StyledErrorMessage>
              <label aria-label="Password Title">{t('createPassword')}</label>
              <StyledPasswordContainer curLang={i18n.language}>
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: true }}
                  render={({
                    field: {
                      onChange, value
                    }
                  }) => (
                    <StyledInput
                      value={value || ''}
                      placeholder={t('enterYourPassword')}
                      aria-label="register password input"
                      valid
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => {
                        const targetValue = e.target.value;
                        if (targetValue.includes(' ')) {
                          onChange(targetValue.split(' ').join(''));
                          setValue('password', targetValue.split(' ').join(''));
                        } else {
                          onChange(targetValue);
                          setValue('password', targetValue);
                        }
                      }}
                    />
                  )}
                />
                <div
                  role="presentation"
                  onClick={displayPasswordHandler}
                  aria-label="Password Icon"
                >
                  <Icon name={showPassword ? 'Show' : 'Hide'} />
                </div>
              </StyledPasswordContainer>
              <StyledCriteriaContainer>
                <StyledCriteria valid={criteria.length} aria-label="Length Check">
                  <StyledCheckWrap>
                    {criteria.length && <Icon name="Check" />}
                  </StyledCheckWrap>
                  {t('charactersLimit')}
                </StyledCriteria>
                <StyledCriteria valid={criteria.lowercase} aria-label="Lowercase Check">
                  <StyledCheckWrap>
                    {criteria.lowercase && <Icon name="Check" />}
                  </StyledCheckWrap>
                  {t('lowercaseLetter')}
                </StyledCriteria>
                <StyledCriteria valid={criteria.uppercase} aria-label="Uppercase Check">
                  <StyledCheckWrap>
                    {criteria.uppercase && <Icon name="Check" />}
                  </StyledCheckWrap>
                  {t('uppercaseLetter')}
                </StyledCriteria>
                <StyledCriteria valid={criteria.number} aria-label="Number Check">
                  <StyledCheckWrap>
                    {criteria.number && <Icon name="Check" />}
                  </StyledCheckWrap>
                  {t('1number')}
                </StyledCriteria>
                <StyledCriteria valid={criteria.specialChar} aria-label="Special Char Check">
                  <StyledCheckWrap>
                    {criteria.specialChar && <Icon name="Check" />}
                  </StyledCheckWrap>
                  {t('1specialChar')}
                </StyledCriteria>
              </StyledCriteriaContainer>

              <StyledCheckboxContainer>
                <StyledCheckbox>
                  <div className={isUbank ? 'ubank' : ''}>
                    <input {...register('policy', { required: true })} aria-label="policy input" type="checkbox" />
                  </div>
                </StyledCheckbox>
                <span aria-label="Agree Policy Question">
                  {`${t('agreePrivacyPolicyPrefix')} `}
                  {isUbank ? <span>{t('privacyPolicy')}</span> : (
                    <StyledPolicyLink
                      aria-label="Agree Policy Link"
                      onClick={() => {
                        const windowReference = window.open();
                        const curLang = i18n.language as Languages;

                        if (windowReference) {
                          windowReference.location = watchCountry === Countries.AUS || !watchCountry
                            ? `https://acy.com.au/${Lang.getACYLocale(curLang)}/support/privacy-policy`
                            : `https://acy.com/${Lang.getACYLocale(curLang)}/support/privacy-policy`;
                        }
                      }}
                    >
                      {t('privacyPolicy')}
                    </StyledPolicyLink>
                  )}
                  {` ${t('agreePrivacyPolicySuffix')}`}
                </span>
              </StyledCheckboxContainer>
              <StyledCheckboxContainer>
                <StyledCheckbox>
                  <div className={isUbank ? 'ubank' : ''}>
                    <input {...register('subscription')} type="checkbox" />
                  </div>
                </StyledCheckbox>
                <span aria-label="Receive Trading">
                  {t(isUbank ? 'OptUBank' : 'OptAcy')}
                </span>
              </StyledCheckboxContainer>
              <Button
                aria-label="continue button"
                type="button"
                onClick={handleClick}
                disabled={!isValid || !validPassword}
                className={isUbank ? 'customUBankButton' : 'customButton'}
              >
                {t('continue')}
              </Button>
            </div>
            <StyledAlreadRegisterWrap ubank={isUbank}>
              {t('alreadyRegistered')}
              <a href="/">{t('login')}</a>
            </StyledAlreadRegisterWrap>
          </StyledForm>
        </>
      ) : (
        <StyledLoadingWrap>
          <CircularProgress />
        </StyledLoadingWrap>
      )}

    </div>
  );
};

export default CheckEmailForm;
