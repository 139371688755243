import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import IconComponent from 'components/Icons';
import BackdropSpinner from 'components/BackdropSpinner';
import { useLayoutControlState } from 'containers/AccountList/context/layoutControlContext';
import CollapsibleTable from 'containers/AccountList/components/CollapsibleTable';
import AlertToast from 'components/AlertToast';
import { RouterPath } from 'type/common';
import {
  StyledAccountListWrapper,
  StyledContent,
  StyledHeader,
  StyledPlusIcon
} from './style';
import RemoveMemberDialog from './components/RemoveMemberDialog';
import InviteMemberDialog from './components/InviteMemberDialog';
import TradingExperienceDialog from './components/TradingExperienceDialog';
import { useAccountListState } from './context/accountListContext';
import { useStagingDataState } from './context/stagingDataContext';

const AccountListContainer = (): JSX.Element => {
  const { t } = useTranslation('accountList');
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isBackdropSpinnerOpen,
    setIsInviteMemberDialogOpen,
    isResponseMessageToastOpen,
    setIsResponseMessageToastOpen,
    responseMessage
  } = useLayoutControlState();
  const { accountList, isLoading } = useAccountListState();
  const { setAccountData } = useStagingDataState();

  // NOTE: https://zerologix.atlassian.net/browse/LP1-5475
  // InviteMemberDialog would open for url format /account-list?trader_id=xxx&invite_member=true
  useEffect(() => {
    if (!location.search) return;

    const searchParams = new URLSearchParams(location.search.substring(1));
    const traderId = searchParams.get('trader_id');
    const inviteMember = searchParams.get('invite_member');

    if (traderId && inviteMember === 'true') {
      const account = accountList.find(
        (_account) => _account.traderId === traderId
      );
      if (account) {
        setAccountData(account);
        setIsInviteMemberDialogOpen(true);
        navigate('/account-list', { replace: true });
      }
    }
  }, [location.search, accountList]);

  return (
    <StyledAccountListWrapper>
      <StyledHeader>
        <span aria-label="Account list title">{t('accounts')}</span>
        <StyledPlusIcon
          onClick={() => navigate(RouterPath.createAccountSelectAccountType)}
        >
          <IconComponent name="DemoAccountPlus" />
          <span aria-label="add account">{t('addAccount')}</span>
        </StyledPlusIcon>
      </StyledHeader>
      <StyledContent>
        <CollapsibleTable />
      </StyledContent>
      <BackdropSpinner open={isLoading || isBackdropSpinnerOpen} />
      <RemoveMemberDialog />
      <InviteMemberDialog />
      <TradingExperienceDialog />
      <AlertToast
        isOpen={isResponseMessageToastOpen}
        handleClose={() => setIsResponseMessageToastOpen(false)}
        severity={responseMessage.type}
        title={responseMessage.title}
        content={responseMessage.content}
      />
    </StyledAccountListWrapper>
  );
};

export default AccountListContainer;
