import Steps from 'components/Steps';
import Step from 'components/Steps/Step';
import { useAppSelector } from 'store';
import CreateCorporateAccountForm from 'containers/CreateCorporateAccountForm';
import { useSearchParams } from 'react-router-dom';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import { CreateCorporateAccountSteps } from 'constant/createAccount';
import { useTranslation } from 'react-i18next';
import { StyledBanner, StyledTopWrapper } from './style';

interface translationProps {
  [key: string]: string
}

const progressStepTranslation: translationProps = {
  [CreateCorporateAccountSteps.CorporateContactPersonDetails]: 'Contact Person Details',
  [CreateCorporateAccountSteps.CorporateCompanyDetails]: 'Company Details & Preference',
  [CreateCorporateAccountSteps.CorporateShareholdersAndDirectors]: 'Shareholders & Directors',
  [CreateCorporateAccountSteps.CorporateAppropriateness]: 'Appropriateness',
  [CreateCorporateAccountSteps.CorporateTermsAndConditions]: 'Terms & Conditions',
  [CreateCorporateAccountSteps.CorporateConfirmID]: 'Confirm ID'
};

const CreateCorporateAccount = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const currentStep = useAppSelector((state) => state.createAccountState.step);
  const [searchParams] = useSearchParams();
  const { steps, rejectStepStatus } = useCreateCorporateAccountFlow();

  const getStatus = (el: { valid: boolean, isWarning: boolean }): 'valid' | 'warning' | 'unfinished' | undefined => {
    if (searchParams.get('status') !== 'Reject') return undefined;
    if (!el) return undefined;
    return (!el.valid || el.isWarning) ? 'warning' : 'valid';
  };
  return (
    <>
      <StyledTopWrapper>
        <StyledBanner>
          <div aria-label="Corporate Account Registration">{t('corporateAccountRegistration')}</div>
          <div aria-label="Instructions for registering a corporate account">{t('accountRegistrationBannerContent')}</div>
        </StyledBanner>
        <Steps current={currentStep + 1}>
          {steps.map((item) => (
            <Step
              description={t(progressStepTranslation[item])}
              key={item}
              ariaLabel={`Step ${progressStepTranslation[item]}`}
              status={getStatus(rejectStepStatus[item])}
            />
          ))}
        </Steps>
      </StyledTopWrapper>
      <CreateCorporateAccountForm />
      {/* <div>
        CreateCorporateAccount
      </div> */}
    </>
  );
};

export default CreateCorporateAccount;
