export enum TradingPlatform {
  LogixTrader = 'LogixTrader',
  MetaTrader4 = 'MetaTrader4',
  MetaTrader5 = 'MetaTrader5',
}

export enum WHITE_LABELS {
  ubank = 'ubank',
}

export type WhiteLabelConfig = {
  prod: string;
  dev: string;
  realm: string;
};

export type DomainConfigType = {
  realm: string;
  keycloak: string;
  api: string;
  socket: string;
  origin: string;
}

export enum AccountStatus {
  Active = 'Active',
  Pending = 'Pending',
  Approve = 'Approve',
  Reject = 'Reject',
  Blocked = 'Blocked',
  Incomplete = 'Incomplete',
  Resubmitted = 'Resubmitted',
  Auto_Active = 'Auto_Active',
  Knockout = 'Knockout',
  noAccount = 'noAccount'
}

export enum AccountType {
  personal = 'personal',
  corporate = 'corporate',
  joint = 'joint'
}

export enum Regulation {
  ASIC = 'ASIC',
  VFSC = 'VFSC',
}

export interface Account {
  can_retry_rg227: boolean;
  form_step: string;
  id: string;
  is_blocked_by_rg227: boolean;
  name: string;
  regulation: 'asic' | 'SVG FSA';
  status: AccountStatus;
  traderId: string;
  type: AccountType;
}

export enum RouterPath {
  Home = '/',
  createAccount = '/create-account',
  createAccountSelectAccountType = '/create-account/select-account-type',
  createAccountVerifyIdentity = '/create-account/verify-identity',
  createAccountPersonalAccount = '/create-account/personal-account',
  createAccountPersonalAccountThankYou = '/create-account/personal-account/thank-you',
  createAccountCorporateAccount = '/create-account/corporate-account',
  createAccountCorporateAccountThankYou = '/create-account/corporate-account/thank-you',
  createAccountJointAccount = '/create-account/joint-account',
  createAccountJointAccountThankYou = '/create-account/joint-account/thank-you',

  rg227Practice = '/rg227-practice',
  rg227 = '/rg227',
  rg227ThankYou = '/rg227/thank-you',
  rg227Unsuccessful = '/rg227/unsuccessful',
}
