import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getAccountPortalLoginURL, getAccounts } from 'api/v1/account';
import { AccountStatus } from 'type/common';
import BackdropSpinner from 'components/BackdropSpinner';

const REDIRECT_QUERY_STORAGE_KEY = 'client_cloudhub_redirect_query';

const ClientCloudhubRedirect = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      getAccounts().then((res) => {
        if (res.status === 200 && res.data) {
          const { count: activeAccountCount, firstMatch: activeAccount } = res.data.reduce(
            (
              accumulator: {
                  count: number;
                  firstMatch: typeof currentObj | null;
                },
              currentObj: { [key: string]: string }
            ) => {
              if (currentObj.status === AccountStatus.Active || currentObj.status === AccountStatus.Auto_Active) {
                accumulator.count += 1;
                if (!accumulator.firstMatch) {
                  accumulator.firstMatch = currentObj;
                }
              }
              return accumulator;
            },
            { count: 0, firstMatch: null }
          );

          if (activeAccountCount === 1) {
            getAccountPortalLoginURL(activeAccount.id, params['*']).then(
              (_res) => {
                if (_res.status === 200 && _res.data) {
                  const query = location.search || sessionStorage.getItem(REDIRECT_QUERY_STORAGE_KEY) || '';
                  sessionStorage.removeItem(REDIRECT_QUERY_STORAGE_KEY);
                  window.location.replace(`${_res.data.login_url}${query && encodeURIComponent(`&trigger_action=true&${query.slice(1)}`)}`);
                }
              }
            );
          } else {
            sessionStorage.removeItem(REDIRECT_QUERY_STORAGE_KEY);
            navigate('/account-list', { replace: true });
          }
        }
      });
    } else {
      // Since it might be complicated for keycloak to bring the query,
      // it's saved in session storage so that we could retrieve once user logged in through keycloak.
      sessionStorage.setItem(REDIRECT_QUERY_STORAGE_KEY, location.search);
    }
  }, []);

  return <BackdropSpinner open />;
};

export default ClientCloudhubRedirect;
