import { DomainConfigType, WHITE_LABELS, WhiteLabelConfig } from 'type/common';
import { parseURL } from 'utils/url.util';

const WHITE_LABELS_CONFIG: Record<WHITE_LABELS, WhiteLabelConfig> = {
  [WHITE_LABELS.ubank]: {
    realm: WHITE_LABELS.ubank,
    prod: 'https://unitedco.zerologix.com',
    dev: 'https://ubank.cp-logixpanel-dev.zerologixtesting.com'
  }
};

/**
 * Retrieves the domain configuration based on the current environment and url.
 * @returns A DomainConfigType object containing realm, keycloak, api, and socket URLs.
 */
const getDomainConfig = (): DomainConfigType => {
  let realm = 'acy';
  let keycloak = '';
  let api = '';
  let socket = '';

  const { domain, origin } = parseURL();

  const isLocal = process.env.REACT_APP_NODE_ENV === 'local';
  const isDev = process.env.REACT_APP_NODE_ENV === 'dev';
  const isLocalBuild = process.env.REACT_APP_NODE_ENV === 'localBuild';

  const isAcycn = domain === 'acycn.cloud';

  Object.values(WHITE_LABELS).forEach((key) => {
    const isWhiteLabel = origin === WHITE_LABELS_CONFIG[key][isDev ? 'dev' : 'prod'];
    if (isWhiteLabel) realm = WHITE_LABELS_CONFIG[key].realm;
  });

  keycloak = `https://accounts.${domain}/auth/realms/${realm}/protocol/openid-connect`;
  api = `https://api-cmp.${domain}/api/v1`;
  socket = isAcycn ? 'https://ws.acy.cloud' : `https://ws.${domain}`;

  if (isLocal || isDev) {
    socket = 'https://zl-websocket-zerocloud-dev.zeroclouddev.com';
  }

  if (isLocalBuild) {
    keycloak = process.env.REACT_APP_OIC_ENDPOINT || keycloak;
    api = process.env.REACT_APP_BASE_URL || api;
    socket = process.env.REACT_APP_WEBSOCKET_ENDPOINT || socket;
  }

  return {
    realm,
    keycloak,
    api,
    socket,
    origin
  };
};

export const DOMAIN_CONFIG = getDomainConfig();
