import { useEffect } from 'react';

interface GTMInfo {
  gtmIframeSrc: string;
  gtmScript: string;
}

const GTM_IDS = {
  production: 'GTM-TBGGKFH',
  development: 'GTM-PKSWFJK',
  test: ''
} as const;

const getGTMInfo = (): GTMInfo => {
  const env = process.env.NODE_ENV;
  const id = GTM_IDS[env];

  if (!id) {
    return { gtmIframeSrc: '', gtmScript: '' };
  }

  const url = new URL('https://www.googletagmanager.com/ns.html');
  const params = new URLSearchParams({ id });
  url.search = params.toString();

  const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');`;

  return { gtmIframeSrc: url.href, gtmScript };
};

const useZendeskScript = (): void => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=cf04c151-2df1-4907-acac-fba7c414e126';
    script.id = 'ze-snippet';
    script.async = true;

    try {
      document.body.appendChild(script);
    } catch (error) {
      console.error('Error appending Zendesk script:', error);
    }

    return () => {
      if (script.parentNode) script.parentNode.removeChild(script);
    };
  }, []);
};

const useFacebookDomainVerification = (): void => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.content = 'esot97acbzzdzxuzkaf71uhoc9awjf';
    meta.name = 'facebook-domain-verification';

    try {
      if (process.env.NODE_ENV === 'production') {
        document.head.appendChild(meta);
      }
    } catch (error) {
      console.error('Error appending Facebook domain verification:', error);
    }

    return () => {
      if (meta.parentNode) meta.parentNode.removeChild(meta);
    };
  }, []);
};

const GTMIframe = (): React.ReactElement | null => {
  const { gtmIframeSrc, gtmScript } = getGTMInfo();

  useZendeskScript();
  useFacebookDomainVerification();

  useEffect(() => {
    const gtmScriptEle = document.createElement('script');
    gtmScriptEle.type = 'text/javascript';
    gtmScriptEle.async = true;

    if (gtmScript) {
      gtmScriptEle.innerHTML = gtmScript;
    }

    try {
      document.head.appendChild(gtmScriptEle);
    } catch (error) {
      console.error('Error appending GTM script:', error);
    }

    return () => {
      if (gtmScriptEle.parentNode) gtmScriptEle.parentNode.removeChild(gtmScriptEle);
    };
  }, [gtmScript]);

  if (!gtmIframeSrc) return null;

  return (
    <noscript>
      <iframe
        title="This is for GTM"
        src={gtmIframeSrc}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

export default GTMIframe;
