import { appDayjs } from './appDayjs.util';

class KYC {
  /**
  * Validates if a date falls within the valid age range
  *
  * @param {string} date - Date of birth in any format supported by dayjs
  * @param {boolean} isAUS - Whether to use Australian validation rules
  * @returns {boolean} - Returns true if date is within valid range, false otherwise
  *
  * @description
  * - For Australian region (isAUS = true): Age must be between 18-85 years
  * - For non-Australian region (isAUS = false): Age must be above 18 years
  *
  * @example
  * // Australian region validation (age must be between 18-85)
  * isUserAgeRangeValid('1920-01-01', true) // return false
  *
  * // Non-Australian region validation (age must be above 18)
  * isUserAgeRangeValid('1920-01-01', false) // return true
  */
  static isUserAgeRangeValid = (date: appDayjs.ConfigType, isAUS: boolean): boolean => {
    const minAge = appDayjs().subtract(18, 'year');
    const maxAge = appDayjs().subtract(85, 'year');
    const dateValue = appDayjs(date);

    if (isAUS) {
      return dateValue.isBefore(minAge) && dateValue.isAfter(maxAge);
    }

    return dateValue.isBefore(minAge);
  };
}

export default KYC;
