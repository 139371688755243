/* eslint-disable quote-props */
import CountDown from 'utils/CountDown';
import { useTranslation } from 'react-i18next';
import { appDayjs, Dayjs } from 'utils/appDayjs.util';
import WebinarItemType from '../type';
import switchTimezoneCity from '../switchCityFunc';

import {
  StyledEnrolledTrainingContainer,
  StyledEnrolledTrainingWrap,
  StyledTitle,
  StyledSubTitle,
  StyledTitleContainer,
  StyledZoomLink,
  StyledTimezone,
  StyledTimerContainer,
  StyledTimer,
  StyledEnrolledCoursesHeader
} from './style';

interface WebinarListProps {
  webinarList: WebinarItemType[];
}

const getDayHourMinSecond = (time: number): string => `${appDayjs.duration(time * 1000).format('DD [Day] HH [Hour] mm [Min] ss [Sec]')}`;
const getStartTimeUTC = (time: string, timezone: { utc: string }): Dayjs => {
  const utcHours = timezone.utc.slice(3);
  return appDayjs.utc(time).subtract(Number(utcHours), 'hour');
};

const EnrolledTraining = ({ webinarList }: WebinarListProps):JSX.Element => {
  const { t } = useTranslation('onlineTraining');
  const startTimeParse = (time: string, timezone: { utc: string; city: string; }): string => {
    const utc = timezone ? timezone.utc : '';
    const city = timezone ? timezone.city : '';
    return `${time} (${utc}) ${t(switchTimezoneCity(city))}`;
  };

  return (
    <>
      <StyledEnrolledCoursesHeader>
        <h4 aria-label="Enrolled Training Subtitle">{t('enrolledTrainingSubtitle')}</h4>
      </StyledEnrolledCoursesHeader>
      <StyledEnrolledTrainingWrap>
        {webinarList.map((item: WebinarItemType) => (
          !!item.enrolled
        && (
        <StyledEnrolledTrainingContainer key={item.id}>
          <StyledTitleContainer>
            <StyledTitle aria-label="enrolled title">{item.title}</StyledTitle>
            <StyledSubTitle aria-label="enrolled subtitle">{item.subtitle}</StyledSubTitle>
          </StyledTitleContainer>
          <div>
            <div>
              <StyledZoomLink aria-label="enrolled link" href={item.course_link}>
                {item.course_link}
                {' '}
                {item.extra_info || ''}
              </StyledZoomLink>
              <StyledTimezone aria-label="enrolled start time">{startTimeParse(item.start_time, item.timezone)}</StyledTimezone>
            </div>
            <StyledTimerContainer>
              <StyledTimer>
                <span aria-label="timer">
                  {t('timer')}
                  :&nbsp;
                </span>
                <span aria-label="countdown time">
                  {appDayjs().isAfter(item.start_time)
                    ? `${t('Started')}` : getDayHourMinSecond(CountDown({ startUtcTime: getStartTimeUTC(item.start_time, item.timezone) }))}
                </span>
              </StyledTimer>
            </StyledTimerContainer>
          </div>
        </StyledEnrolledTrainingContainer>
        )
        ))}
      </StyledEnrolledTrainingWrap>
    </>
  );
};

export default EnrolledTraining;
