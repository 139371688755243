import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ExperienceQuestionForm from 'containers/ExperienceQuestionForm/index';
import { StyledCreateAccountFrom } from 'containers/CreateAccountForm/style';
import Banner from 'containers/Banner';
import { getAccounts } from 'api/v1/account';
import useSWR from 'swr';
import { Account, AccountStatus } from 'type/common';

const ExperienceQuestion = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data, isLoading
  } = useSWR(['/accounts', 'get'], getAccounts, { dedupingInterval: 2000, revalidateOnFocus: false });

  useEffect(() => {
    if (!data || isLoading) return;

    const pathNames = location.pathname.split('/');
    const accountId = pathNames[2];
    const account = data.data.find(
      (_account: Account) => _account.id === accountId
    );

    if (!account || account.status === AccountStatus.Knockout) {
      navigate('/', { replace: true });
    }
    if (account.status === AccountStatus.Incomplete) {
      navigate(`/create-account/${account.type}-account`, { replace: true });
    }
    if (account.is_blocked_by_rg227) {
      navigate('/account-list', { replace: true });
    }
  }, [data, isLoading, location.pathname, navigate]);

  return (
    <>
      <Banner name="AccountRegistrationBanner" />
      <StyledCreateAccountFrom>
        <ExperienceQuestionForm />
      </StyledCreateAccountFrom>
    </>
  );
};

export default ExperienceQuestion;
