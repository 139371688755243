import styled from 'styled-components';
import { Button } from '@mui/material';
import Paper from 'components/Paper';
import { device } from 'styles/deviceStyle';

export const StyledPaper = styled(Paper)`
  padding: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const StyledHeader = styled.div`
  padding: 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;

  @media ${device.mobileMaxWidth} {
    padding: 24px 16px;
  }
`;

export const StyledImage = styled.img`
  width: 64px;
  height: 64px;
`;

export const StyledTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #212121;
`;

export const StyledContent = styled.div`
  padding: 24px 32px;
  background-color: #f6f6f6;

  @media ${device.tablet} {
    padding: 40px 63px;
  }

  @media ${device.mobileMaxWidth} {
    padding: 30px 20px;
  }
`;

export const StyledBox = styled.div`
  padding: 20px;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const StyledContentTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  color: #3E3E3E;
  padding: 0 30px 8px;
  border-bottom: 1px solid #dddddd;
`;

export const StyledContentDescription = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  color: #3E3E3E;

  a {
    color: #0268A6;
  }
`;

export const StyledActionGroup = styled.div`
  margin-top: 12px;
`;

export const StyledActionButton = styled(Button)`
  background: #145DB5;
  border-radius: 8px;
  padding: 8px 24px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Noto Sans';
  text-transform: capitalize;

  &:hover,
  &:active {
    background: #145DB5;
  }

  @media ${device.mobileMaxWidth} {
    font-size: 12px;
    line-height: 16px;
  }
`;
