import React, {
  useState, useMemo
} from 'react';
import { CreateJointAccountSteps } from 'constant/createAccount';
import { Regulation } from 'type/common';
import {
  CreateJointAccountContextType, RejectStepStatusProps, RejectWarningFieldsProps, SanctionedCountriesInfoProps
} from '../types';

interface ContextProps {
  children: React.ReactNode
}

export const CreateJointAccountContext = React.createContext({} as CreateJointAccountContextType);

const CreateJointAccountContextProvider = (props:ContextProps):JSX.Element => {
  const { children } = props;
  const [flow, setFlow] = useState<string>(Regulation.ASIC);
  const [formIsLoading, setFormIsLoading] = useState(false);

  const [steps, setSteps] = useState<string[]>([
    CreateJointAccountSteps.JointPrimaryAccountHolder,
    CreateJointAccountSteps.JointSecondaryAccountHolder,
    CreateJointAccountSteps.JointTradingPreference,
    CreateJointAccountSteps.JointAppropriateness,
    CreateJointAccountSteps.JointTermsAndConditions,
    CreateJointAccountSteps.JointConfirmID
  ]);
  const [sanctionedCountriesInfo, setSanctionedCountriesInfo] = useState<SanctionedCountriesInfoProps>({
    countries: [],
    message: ''
  });

  const [rejectStepStatus, setRejectStepStatus] = useState<RejectStepStatusProps>({
    [CreateJointAccountSteps.JointPrimaryAccountHolder]: {
      valid: true,
      isWarning: true
    },
    [CreateJointAccountSteps.JointSecondaryAccountHolder]: {
      valid: true,
      isWarning: true
    },
    [CreateJointAccountSteps.JointTradingPreference]: {
      valid: true,
      isWarning: true
    },
    [CreateJointAccountSteps.JointAppropriateness]: {
      valid: true,
      isWarning: true
    },
    [CreateJointAccountSteps.JointTermsAndConditions]: {
      valid: true,
      isWarning: true
    },
    [CreateJointAccountSteps.JointConfirmID]: {
      valid: true,
      isWarning: true
    }
  });
  const [rejectWarningFields, setRejectWarningFields] = useState<RejectWarningFieldsProps>({});

  const updateFlow = (val:string):void => setFlow(val);

  const updateSteps = (val:string[]):void => setSteps(val);

  const updateFormIsLoading = (val:boolean):void => setFormIsLoading(val);

  const updateRejectStepStatus = (obj:RejectStepStatusProps):void => setRejectStepStatus(obj);

  const updateRejectWarningFields = (obj:RejectWarningFieldsProps):void => setRejectWarningFields(obj);
  const updateRejectStepWarningStatus = (step: string, val:boolean):void => setRejectStepStatus((prev) => (
    {
      ...prev,
      [step]: {
        isWarning: val,
        valid: prev[step].valid
      }
    }
  ));
  const updateRejectStepValidStatus = (step: string, val:boolean):void => setRejectStepStatus((prev) => (
    {
      ...prev,
      [step]: {
        valid: val,
        isWarning: prev[step].isWarning
      }
    }
  ));

  const updateSanctionedCountriesInfo = (obj:SanctionedCountriesInfoProps):void => setSanctionedCountriesInfo(obj);

  const contextValue:CreateJointAccountContextType = useMemo(() => ({
    flow,
    steps,
    formIsLoading,
    rejectStepStatus,
    rejectWarningFields,
    sanctionedCountriesInfo,
    updateFlow,
    updateSteps,
    updateFormIsLoading,
    updateRejectStepStatus,
    updateRejectStepWarningStatus,
    updateRejectStepValidStatus,
    updateRejectWarningFields,
    updateSanctionedCountriesInfo
  }), [
    flow,
    steps,
    formIsLoading,
    rejectStepStatus,
    rejectWarningFields,
    sanctionedCountriesInfo,
    updateFlow,
    updateSteps,
    updateFormIsLoading,
    updateRejectStepStatus,
    updateRejectStepWarningStatus,
    updateRejectStepValidStatus,
    updateRejectWarningFields,
    updateSanctionedCountriesInfo
  ]);

  return (
    <CreateJointAccountContext.Provider value={contextValue}>
      {children}
    </CreateJointAccountContext.Provider>
  );
};

export default CreateJointAccountContextProvider;
