/* eslint-disable no-alert */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCountryList, getSanctionedCountryList } from 'api/v1/user';
import { createAccount } from 'api/v1/account';
import Select from 'components/Select';
import Button from 'components/Button';
import IconComponent from 'components/Icons';
import BackdropSpinner from 'components/BackdropSpinner';
import PopUp from 'components/PopUp';
import { useAppDispatch } from 'store';
import { setAccountStatus, setCreateAccountStatus } from 'containers/Pages/slices';
import { isEmpty } from 'lodash';

import { AccountStatus, AccountType, RouterPath } from 'type/common';
import {
  StyledModalWrapper, StyleSelectedCountry, StyledButtonGroup, StyledHeader, StyledContent, StyledIconWrap, StyledTitle, StyledBtnWrap
} from './style';

interface CountryModalProp {
  isModalOpen: boolean;
  handleClose: () => void;
}

const CountryModal = (props: CountryModalProp): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('selectAccountType');
  const defaultCountry = localStorage.getItem('registeredCountry') || '';
  const { isModalOpen, handleClose } = props;
  const [countries, setCountries] = useState([]);
  const [SanctionedCountries, setSanctionedCountries] = useState(['']);
  const [country, setCountry] = useState(defaultCountry);
  const [isLoading, setIsLoading] = useState(false);
  const [popupTxt, setPopupTxt] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.setItem('Individual_Account_Country', defaultCountry);
    getCountryList().then((res) => {
      if (res && res.status === 200) {
        setCountries(res?.data?.options);
      }
    });

    getSanctionedCountryList().then((res) => {
      if (res && res.status === 200) {
        setSanctionedCountries(res?.data?.countries);
      }
    });
  }, []);

  const onChange = (value:string):void => {
    setCountry(value);
    localStorage.setItem('Individual_Account_Country', value);
  };

  const errorMsg = SanctionedCountries.indexOf(country) > -1 ? t('rejectThisCountry') : '';

  const createPersonalAccount = (): void => {
    setIsLoading(true);
    createAccount({ account_type: AccountType.personal, country }).then((res) => {
      if (res && res.status === 201) {
        localStorage.setItem('accountID', res.data.trader.resource_account_id);
        dispatch(setAccountStatus({ accountStatus: AccountStatus.Incomplete }));
        dispatch(setCreateAccountStatus({
          createAccountStatus: {
            type: AccountType.personal, isOnBoardingMethodSelecting: true, isModalOpen: false
          }
        }));
        navigate(RouterPath.createAccountVerifyIdentity);
      }
    }).catch((err) => {
      if (err.response.status === 422) {
        alert(err.response.data.errors);
      }
      if (err.response.status === 409 || err.response.status === 500) {
        setPopupTxt(err.response.data.message);
      }
    }).finally(() => {
      handleClose();
      setIsLoading(false);
    });
  };

  const handleWarningClose = (): void => {
    setPopupTxt('');
    setIsLoading(false);
  };

  const resetCountry = (): void => {
    setCountry(defaultCountry);
    setPopupTxt('');
    handleClose();
  };

  return (
    <>
      <StyledModalWrapper
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
      >
        <StyleSelectedCountry aria-label="Country Modal">
          <div className="country-modal-container">
            <StyledHeader>
              <StyledTitle aria-label="Country Modal Title">{t('countryOfResidence')}</StyledTitle>
              <StyledIconWrap aria-label="Close Country Modal" onClick={resetCountry}>
                <IconComponent name="Close" />
              </StyledIconWrap>
            </StyledHeader>
            <StyledContent aria-label="Country Modal Content">{t('selectCountryOfResidence')}</StyledContent>
            <Select
              options={countries}
              inputHeight="48px"
              onChange={onChange}
              currentValue={country}
              errorMsg={errorMsg}
              id="country-select"
            />

            <StyledButtonGroup>
              <Button aria-label="Cancel Select Country" className="cancel-button" onClick={resetCountry}>{t('cancel')}</Button>
              <Button
                className="next-button"
                disabled={!isEmpty(errorMsg) || isEmpty(country)}
                onClick={createPersonalAccount}
                aria-label="Select Country Next"
              >
                {t('next')}
              </Button>
            </StyledButtonGroup>
          </div>
          <BackdropSpinner open={isLoading} />
        </StyleSelectedCountry>
      </StyledModalWrapper>
      <PopUp
        content={popupTxt}
        openModal={popupTxt !== ''}
        onClose={handleWarningClose}
      >
        <StyledBtnWrap>
          <Button
            aria-label="Pop Up OK"
            onClick={handleWarningClose}
          >
            ok
          </Button>
        </StyledBtnWrap>
      </PopUp>
    </>
  );
};

export default CountryModal;
