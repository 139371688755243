import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledLButtonsWrap = styled.div`
  margin: 24px 0 56px;
  display: flex;
  align-items: center;


  > div:first-child {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 24px;
  }

  button {
    border-radius: 8px;
    min-width: 0;
    padding: 8px 24px;
    font-size: 14px;

    &.back-button{
      color: #145DB5;
      background: #f6f6f6;
      border-color: #145DB5;
      border-radius: 8px;
      &:hover,
      &:focus {
          color: #145DB5;
          border-color: #145DB5;
      }
    }
    &.next-button{
      color: #FFFFFF;
      border-color: #145DB5;
      background-color: #145DB5;

      &:hover,
      &:focus {
        color: #FFFFFF;
        border-color: #145DB5;
        background-color: #145DB5;
      };
      &:disabled {
        color: #FFFFFF;
        border-color:  #145DB5;
        background-color: #145DB5;
        opacity: 0.6;
      }

      &.hidden {
        display: none;
      }
    }
  }

  .custom-submitting-progress{
    position: absolute;
    left: calc(50% - 14px);
    top: calc(50% - 14px);
  }

  @media ${device.mobileMaxWidth} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
