/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import FormPanel from 'components/FormPanel';
import ItemLabel from 'components/ItemLabel';
import RadioGroup from 'components/RadioGroup';
import { getExperienceForm, createExperienceTest } from 'api/v1/account';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { useCreateCorporateAccountFlow } from 'store/context/hooks';
import { useLocation } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { checkFieldIsWarning } from 'containers/CreateAccountForm/utils';
import { CreateAccountSteps } from 'constant/createAccount';
import { RouterPath } from 'type/common';
import { RG227Question } from 'type/rg227';
import ResponseMessagePaper from 'components/ResponseMessagePaper';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { StyledActionButton } from 'components/ResponseMessagePaper/style';
import { StyledLoadingWrap } from '../style';
import { StyledRadioWrap, StyledQuestionWrap, StyledContentWrap } from './style';

const PreferenceExperience = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const location = useLocation();
  const { control, setValue, getValues } = useFormContext();
  const { rejectWarningFields, updateFormIsLoading, formIsLoading } = useCreateCorporateAccountFlow();
  const [rg227Questions, setRG227Questions] = useState<RG227Question[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isStartTest, setIsStartTest] = useState(() => Boolean(getValues('rg227_test_id')));

  useEffect(() => {
    if (!isStartTest) return;

    const accountID = localStorage.getItem('accountID') || '';

    updateFormIsLoading(true);
    getExperienceForm(accountID)
      .then((res) => {
        if (!res) return;

        const hasTest = res.data.length > 0;
        const isCreatingAccount = location.pathname === RouterPath.createAccountCorporateAccount;

        if (hasTest) {
          const latestTest = res.data[0];
          setValue('rg227_test_id', latestTest.testId);
          setRG227Questions(latestTest.questions ?? []);
        }

        if (!hasTest && isCreatingAccount) {
          createExperienceTest(accountID)
            .then((response) => {
              setValue('rg227_test_id', response?.data[0]?.testId);
              setRG227Questions(response?.data[0]?.questions || []);
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              updateFormIsLoading(false);
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        updateFormIsLoading(false);
        setIsLoading(false);
      });
  }, [isStartTest, location.pathname]);

  if (!isStartTest) {
    return (
      <ResponseMessagePaper
        image="AppropriatenessAttention"
        imageAlt="Appropriateness Icon"
        title={t('appropriatenessTest')}
        contentTitle={t('attention')}
        contentDescription={(
          <div style={{ maxWidth: '672px' }} aria-label="Appropriateness Test Description">
            {t('appropriatenessAttention.0')}
            {' '}
            <a target="_blank" href={`${DOMAIN_CONFIG.origin}/rg227-practice`} rel="noreferrer" aria-label="Go To Practice Questions">
              {t('appropriatenessAttention.1')}
            </a>
            {' '}
            {t('appropriatenessAttention.2')}
            {' '}
            <a target="_blank" href={`${DOMAIN_CONFIG.origin}/demo-account`} rel="noreferrer" aria-label="Go To Demo Account">
              {t('appropriatenessAttention.3')}
            </a>
            {t('appropriatenessAttention.4')}
          </div>
          )}
        actionButtonSlot={(
          <StyledActionButton onClick={() => setIsStartTest(true)}>
            {t('start')}
          </StyledActionButton>
            )}
      />
    );
  }

  return (
    <div>
      <FormPanel
        id="Appropriateness Test"
        title={t('appropriatenessTest')}
        description={t('answerFewQuestions')}
      >
        {isLoading
          ? (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )
          : (
            <StyledContentWrap>
              {rg227Questions.map((tradingQuesObj: RG227Question, index) => (
                <StyledQuestionWrap key={tradingQuesObj.id}>
                  <ItemLabel id={tradingQuesObj.id} title={`${index + 1}. ${tradingQuesObj.name}`}>
                    <StyledRadioWrap>
                      <Controller
                        control={control}
                        name={tradingQuesObj.id}
                        rules={{ required: true }}
                        render={({
                          field: {
                            onChange, value
                          }
                        }) => (
                          <RadioGroup
                            options={tradingQuesObj.data}
                            groupName={tradingQuesObj.id}
                            optionType={tradingQuesObj.type === 'radioButton' ? 'button' : undefined}
                            onChange={onChange}
                            value={value}
                            disabled={formIsLoading
                              || checkFieldIsWarning(rejectWarningFields, CreateAccountSteps.Appropriateness, tradingQuesObj.id)}
                          />
                        )}
                      />
                    </StyledRadioWrap>
                  </ItemLabel>
                </StyledQuestionWrap>
              ))}
            </StyledContentWrap>
          )}
      </FormPanel>
    </div>
  );
};

export default PreferenceExperience;
