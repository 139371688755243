import { useEffect } from 'react';
import { getAccounts } from 'api/v1/account';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'store';
import BackdropSpinner from 'components/BackdropSpinner';
import AccountStatusHandler from 'utils/AccountStatusHandler';
import { AccountStatus, AccountType, RouterPath } from 'type/common';
import { setCreateAccountStatus } from 'containers/Pages/slices';
import { CreateAccountSteps } from 'constant/createAccount';

const IncompleteAccountRedirect = ():JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAccounts().then((account) => {
      const target: { [key: string]: {[key:string]:string} } = {};
      account?.data?.forEach((el: { [key: string]: string }) => {
        target[`${el.type}_${el.status}`] = {
          accountID: el.id,
          formStep: el.form_step
        };
      });

      const { accountStatus, type } = AccountStatusHandler(target, dispatch);

      if (accountStatus !== AccountStatus.Incomplete) {
        navigate(RouterPath.Home);
        return;
      }

      const accountTypeHandler = {
        [AccountType.personal]: () => {
          const isAccountCreated = target?.personal_Incomplete?.formStep === CreateAccountSteps.PersonalAccountCreated;

          if (isAccountCreated) {
            dispatch(setCreateAccountStatus({ createAccountStatus: { isModalOpen: true } }));
            navigate(RouterPath.Home);
          } else {
            navigate(RouterPath.createAccountPersonalAccount);
          }
        },
        [AccountType.corporate]: () => navigate(RouterPath.createAccountCorporateAccount),
        [AccountType.joint]: () => navigate(RouterPath.createAccountJointAccount)
      };

      accountTypeHandler[type]?.();
    });
  }, [dispatch, navigate]);

  return (
    <BackdropSpinner open />
  );
};

export default IncompleteAccountRedirect;
