/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from 'components/DropDown';
import IconComponent from 'components/Icons';
import { useAppSelector, useAppDispatch } from 'store';
import { toggleSideMenu } from 'containers/SideMenu/slices';
import { Button } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { device } from 'styles/deviceStyle';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAccountRG227State } from 'store/context/accountRG227Context';
import { LANGUAGE_LIST, Languages } from 'constant/language';
import { AccountStatus, AccountType, RouterPath } from 'type/common';
import {
  StyledHeaderWrap, StyledSettingWrap, StyledLangIcon,
  StyledItemWrap, StyledHeaderMobileWrap, StyledAcyWrapper, StyledContinueBtnWrap, StyledMobileBtnWrap
} from './style';

const hasHeaderBtnStatus = [
  AccountStatus.Incomplete,
  AccountStatus.noAccount,
  AccountStatus.Reject
];

const navigationPath = {
  [AccountType.personal]: RouterPath.createAccountPersonalAccount,
  [AccountType.corporate]: RouterPath.createAccountCorporateAccount,
  [AccountType.joint]: RouterPath.createAccountJointAccount
};

const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation('common');
  const isMenuOpen = useAppSelector((state) => state.sideMenuState.isOpen);
  const accountStatus = useAppSelector((state) => state.pageState.accountStatus);
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery({ query: device.desktopMinWidth });
  const isMobile = useMediaQuery({ query: device.mobileMaxWidth });
  const createAccountStatus = useAppSelector((state) => state.pageState.createAccountStatus);
  const { isBlockedByRg227, canRetryRg227, accountId } = useAccountRG227State();

  const handleIncomplete = ():void => {
    if (createAccountStatus?.isOnBoardingMethodSelecting) {
      navigate(RouterPath.createAccountVerifyIdentity);
    } else {
      navigate(navigationPath[createAccountStatus.type]);
    }
  };

  const handleReject = ():void => {
    navigate(`${navigationPath[createAccountStatus.type]}?status=Reject`);
  };

  const changeLangHandler = (newLang: Languages): void => {
    localStorage.setItem('language', newLang);
    navigate(0);
  };

  const customLangMenu = Object.entries(LANGUAGE_LIST).map(([code, name]) => (
    <StyledItemWrap
      key={code}
      tabIndex={0}
      role="button"
      aria-label={`Select ${name}`}
      onClick={() => changeLangHandler(code as Languages)}
    >
      <StyledLangIcon>
        {i18n.language === code && <IconComponent name="Check" />}
      </StyledLangIcon>
      <span>{name}</span>
    </StyledItemWrap>
  ));

  const renderHeaderBtn = ():React.ReactNode => {
    const isInPath = (path: string): boolean => location.pathname.includes(path);

    if (
      (isInPath(RouterPath.createAccount) && !isInPath(RouterPath.createAccountSelectAccountType))
      || isInPath(RouterPath.rg227ThankYou)
      || isInPath(RouterPath.rg227Unsuccessful)
      || accountStatus === AccountStatus.Knockout
    ) {
      return null;
    }

    if (accountStatus === AccountStatus.Pending && canRetryRg227 && !isBlockedByRg227 && !isInPath(RouterPath.rg227)) {
      return (
        <StyledContinueBtnWrap isSmallSize>
          <Button onClick={() => navigate(`${RouterPath.rg227}/${accountId}`)} aria-label="retry assessment">
            {t('retryAssessment')}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    if (accountStatus === AccountStatus.Incomplete) {
      return (
        <StyledContinueBtnWrap isSmallSize>
          <Button onClick={handleIncomplete} aria-label="account status button">
            {t('continueWhereYouLeft')}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    if (accountStatus === AccountStatus.Reject && !isInPath(RouterPath.rg227)) {
      return (
        <StyledContinueBtnWrap>
          <Button onClick={() => handleReject()} aria-label="account status button">
            {t('finaliseYourApplication')}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    if (accountStatus === AccountStatus.noAccount && !isInPath(RouterPath.createAccountSelectAccountType)) {
      return (
        <StyledContinueBtnWrap>
          <Button onClick={() => navigate(RouterPath.createAccountSelectAccountType)} aria-label="account status button">
            {isMobile ? `${t('openAnAccount')}` : `${t('openALiveAccount')}`}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    return null;
  };

  // const handleOnboardingRedirect = (type:string):void => {
  //   setIsLoading(true);

  //   let url = '';

  //   switch (createAccountStatus?.type) {
  //     case AccountType.personal:
  //       url = '/create-account/personal-account';
  //       break;
  //     case AccountType.corporate:
  //       url = '/create-account/corporate-account';
  //       break;
  //     case AccountType.joint:
  //       url = '/create-account/joint-account';
  //       break;
  //     default:
  //       break;
  //   }

  //   if (type === 'ai') {
  //     // use 10015 if you don't want use Face ID
  //     getJumioInfo(url, String(localStorage.getItem('accountID'))).then((res) => {
  //       if (res && res.data && res.data.data) {
  //         window.location.replace(res.data.data.herf);
  //       }
  //     });
  //   } else {
  //     setIsLoading(true);
  //     dispatch(setCreateAccountStatus(
  //       {
  //         createAccountStatus: { isModalOpen: false }
  //       }
  //     ));
  //     ErrorHandler(updateFormStep(
  //       localStorage.getItem('accountID')!,
  //       CreateAccountSteps.TradingAndEmployment,
  //       localStorage.getItem('registeredCountry')!
  //     ), dispatch)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           dispatch(setCreateAccountStatus(
  //             {
  //               createAccountStatus: { isOnBoardingMethodSelecting: false, isModalOpen: false }
  //             }
  //           ));

  //           navigate(url);
  //         }
  //       }).finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // };

  return isDesktop ? (
    <StyledHeaderWrap isMenuOpen={isMenuOpen} aria-label="Header wrap">
      <div>
        <IconComponent name="AcyIcon" aria-label="ACY logo" />
      </div>
      {renderHeaderBtn()}
      <StyledSettingWrap>
        <DropDown customMenu={customLangMenu} aria-label="language dropdown">
          <span>
            <IconComponent name="Lang" aria-label="Header language icon" />
          </span>
          <span>{LANGUAGE_LIST[i18n.language as Languages]}</span>
          <span>
            <IconComponent name="LangTriangleDown" aria-label="Header language triangle down icon" />
          </span>
        </DropDown>
      </StyledSettingWrap>
    </StyledHeaderWrap>
  ) : (
    <StyledHeaderMobileWrap aria-label="Header wrap">
      <button type="button" onClick={() => dispatch(toggleSideMenu())}>
        <IconComponent name="Hamburger" aria-label="Hamburger icon" />
      </button>
      <StyledAcyWrapper hasBtn={!!accountStatus && hasHeaderBtnStatus.includes(accountStatus)}>
        <IconComponent name={isMobile ? 'AcyLogo' : 'AcyIcon'} aria-label="ACY logo" />
      </StyledAcyWrapper>
      <StyledMobileBtnWrap>
        {renderHeaderBtn()}
      </StyledMobileBtnWrap>
      {!isMobile && (
        <StyledSettingWrap>
          <DropDown customMenu={customLangMenu} aria-label="language dropdown">
            <span>
              <IconComponent name="Lang" aria-label="Header language icon" />
            </span>
            <span>{LANGUAGE_LIST[i18n.language as Languages]}</span>
            <span>
              <IconComponent name="LangTriangleDown" aria-label="Header language triangle down icon" />
            </span>
          </DropDown>
        </StyledSettingWrap>
      )}
    </StyledHeaderMobileWrap>
  );
};

export default Header;
