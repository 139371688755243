/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import FormPanel from 'components/FormPanel';
import UploadFile, { ReturnFiles } from 'components/UploadFileWithAPI';

import { useFormContext, Controller } from 'react-hook-form';
import { useCreateAccountFlow } from 'store/context/hooks';
import { CreateAccountSteps } from 'constant/createAccount';
import { useTranslation } from 'react-i18next';
import { getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting } from 'containers/CreateAccountForm/utils';
import { useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';
import { Regulation } from 'type/common';
import { useMemo, useState } from 'react';
import { StyledContentWrap } from '../style';
import {
  StyledConfirmedId, StyledUploadWrap
} from './style';
import { FILES } from './files';

interface DocumentProps {
  document_id: number;
  file_path: string;
  name: string;
  size: number;
  type: string;
}

const {
  ID_FRONT,
  ID_BACK,
  PROOF_OF_ADDRESS,
  OTHER_DOCUMENTS
} = FILES;

const ConfirmId = () => {
  const currentStep = CreateAccountSteps.ConfirmID;

  const { t } = useTranslation('registrationForm');
  const { control, formState: { errors }, getValues } = useFormContext();
  const {
    method,
    flow,
    rejectWarningFields,
    rejectStepStatus,
    updateRejectStepWarningStatus,
    POACertification
  } = useCreateAccountFlow();

  const [documents, setDocuments] = useState<DocumentProps[]>(getValues('documents'));
  const [searchParams] = useSearchParams();
  const isReject = searchParams.get('status') === 'Reject';

  const displayUploadDocuments = useMemo(() => {
    const isResidenceNotEqualToNationality = !isReject && method === 'AI' && POACertification;
    const isVFSCTraditional = !isReject && flow === Regulation.VFSC && !POACertification;

    if (isResidenceNotEqualToNationality) return [PROOF_OF_ADDRESS];
    if (isVFSCTraditional) return [ID_FRONT, ID_BACK];
    return [ID_FRONT, ID_BACK, PROOF_OF_ADDRESS, OTHER_DOCUMENTS];
  }, [method, flow, isReject, POACertification]);

  const inputChangeHandler = (id: string, index: number, isCancelRejectUpload = false): void => {
    if (!isReject) return;
    if (rejectWarningFields[currentStep] && isArray(rejectWarningFields[currentStep]) && rejectWarningFields[currentStep][index][id]) {
      if (getValues(id) === rejectWarningFields[currentStep][index][id].warning_value) {
        if (!rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      } else {
        for (let j = 0; j < rejectWarningFields[currentStep].length; j += 1) {
          const fieldsArr = Object.keys(rejectWarningFields[currentStep][j]);
          for (let i = 0; i < fieldsArr.length; i += 1) {
            if (rejectWarningFields[currentStep][j][fieldsArr[i]].warning_value === getValues(fieldsArr[i])) {
              if (!rejectStepStatus[currentStep].isWarning) {
                updateRejectStepWarningStatus(currentStep, true);
              }
              return;
            }
          }
        }
        if (rejectStepStatus[currentStep].isWarning) {
          updateRejectStepWarningStatus(currentStep, false);
        } else if (isCancelRejectUpload) {
          updateRejectStepWarningStatus(currentStep, true);
        }
      }
    }
  };

  return (
    <StyledConfirmedId>
      <FormPanel id="upload ID" title={t('uploadDocuments')}>
        <StyledContentWrap>
          <StyledUploadWrap>
            {displayUploadDocuments.map((doc) => {
              const warningMsg = getFieldWarningMessage(rejectWarningFields, currentStep, doc.id, 0);

              return (
                <Controller
                  key={doc.id}
                  control={control}
                  name={doc.id}
                  rules={{ required: warningMsg || isReject ? false : doc.required }}
                  render={({
                    field: { onChange, value }
                  }) => (
                    <UploadFile
                      id={doc.id}
                      onChange={(file: ReturnFiles) => {
                        onChange(file?.info?.file || '');
                        inputChangeHandler(doc.id, 0);
                      }}
                      title={t(doc.title)}
                      fileDescription={t(doc.fileDescription)}
                      files={documents}
                      targetID={0}
                      targetName={doc.id}
                      errorMsg={errors[doc.id]?.message as string}
                      warningMsg={warningMsg}
                      disabled={checkFieldIsWarning(rejectWarningFields, currentStep, doc.id, 0)}
                      markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, doc.id, value, 0) && !!getValues(doc.id)}
                      setFileList={setDocuments}
                    />
                  )}
                />
              );
            })}
          </StyledUploadWrap>
        </StyledContentWrap>
      </FormPanel>
    </StyledConfirmedId>
  );
};

export default ConfirmId;
