/* eslint-disable quote-props */
import { useState, useEffect, KeyboardEvent } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Languages } from 'constant/language';
import DateRange from '@mui/icons-material/DateRange';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { appDayjs, DATE_FORMAT, Dayjs } from 'utils/appDayjs.util';
import {
  StyledWrap, StyledErrMsg, StyledWarningMsg
} from './style';

interface DatePickerProps {
  label?: string;
  className?: string;
  defaultValue?: string;
  errorMsg?: string;
  onChange?: (value: string) => void;
  disabled?:boolean;
  warningMsg?: string | string[];
  markWarning?: boolean;
  id?:string;
  defaultCalendarMonth?: Dayjs;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  disableFuture?: boolean;
}

const langList: Record<Languages, string> = {
  [Languages.ZH_CN]: 'zh-cn',
  [Languages.ZH_TW]: 'zh-tw',
  [Languages.FR]: 'fr',
  [Languages.JA]: 'ja-JP',
  [Languages.KO]: 'ko',
  [Languages.AR]: 'ar-sa',
  [Languages.ID]: 'id',
  [Languages.ES]: 'es',
  [Languages.MS]: 'ms',
  [Languages.PT]: 'pt',
  [Languages.HE]: 'he',
  [Languages.VI]: 'vi',
  [Languages.EN]: 'en'
};

const CustomDatePicker = (props: DatePickerProps): JSX.Element => {
  const { i18n } = useTranslation();
  const curLang = i18n.language as Languages;
  const {
    label, className, defaultValue, onChange, errorMsg, disabled,
    warningMsg, markWarning, id = '', defaultCalendarMonth, maxDate, minDate,
    disableFuture
  } = props;
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [openPicker, setOpenpicker] = useState<boolean>(false);

  useEffect(() => {
    if (!defaultValue || defaultValue === '0000-00-00') {
      setStartDate(null);
      return;
    }
    const isBeforeMaxDate = maxDate ? appDayjs(defaultValue).isBefore(appDayjs(maxDate)) : true;
    const isAfterMinDate = minDate ? appDayjs(defaultValue).isAfter(appDayjs(minDate)) : true;

    if (isAfterMinDate && isBeforeMaxDate) {
      setStartDate(appDayjs(defaultValue));
    }
  }, [defaultValue, maxDate, minDate]);

  const renderWarningMsg = ():React.ReactNode => {
    if (Array.isArray(warningMsg)) {
      return warningMsg.map((eachMsg:string) => (
        <StyledWarningMsg key={eachMsg} markWarning={markWarning} aria-label={`${label} Error Message`}>
          {eachMsg}
        </StyledWarningMsg>
      ));
    }
    return (
      <StyledWarningMsg markWarning={markWarning} aria-label={`${label} Error Message`}>
        {warningMsg}
      </StyledWarningMsg>
    );
  };

  const onKeyDown = (e:KeyboardEvent<HTMLImageElement>):void => e.preventDefault();

  return (
    <StyledWrap label={label} className={className} isErr={!!errorMsg} isWarn={!!warningMsg} markWarning={markWarning}>
      {label && <label aria-label={`${id} date picker title`} htmlFor={id}>{label}</label>}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={langList[curLang]}>
        <DesktopDatePicker
          className="desktop-date-picker"
          inputFormat={DATE_FORMAT['YYYY/MM/DD']}
          value={startDate}
          defaultCalendarMonth={defaultCalendarMonth}
          maxDate={maxDate}
          minDate={minDate}
          disableFuture={disableFuture}
          onChange={(newValue: Dayjs | null) => {
            setStartDate(newValue);
            if (onChange) {
              onChange(newValue ? appDayjs(newValue).format('YYYY-MM-DD') : '');
            }
          }}
          components={{
            OpenPickerIcon: DateRange
          }}
          onClose={() => setOpenpicker(false)}
          open={openPicker}
          disabled={disabled}
          renderInput={(params) => <TextField {...params} autoComplete="off" onKeyDown={onKeyDown} onClick={() => setOpenpicker(true)} />}
        />
        <div className="date-picker-input-msg-wrap">
          {errorMsg && (
          <StyledErrMsg>
            {errorMsg}
          </StyledErrMsg>
          )}
          { warningMsg && renderWarningMsg()}
        </div>
      </LocalizationProvider>
    </StyledWrap>

  );
};

export default CustomDatePicker;
