export const FILES = {
  ID_FRONT: {
    id: 'id_front',
    required: true,
    title: 'idFront',
    fileDescription: 'driverLicence'
  },
  ID_BACK: {
    id: 'id_back',
    required: true,
    title: 'idBack',
    fileDescription: 'driverLicence'
  },
  PROOF_OF_ADDRESS: {
    id: 'proof_of_address',
    required: true,
    title: 'proofAddress',
    fileDescription: 'proofOfAddressDesc'
  },
  OTHER_DOCUMENTS: {
    id: 'other_documents',
    required: false,
    title: 'otherDocument',
    fileDescription: 'proofOfAddressDesc'
  }
} as const;
