import IconComponent from 'components/Icons';
import { useTranslation } from 'react-i18next';
import { TradingPlatform } from 'type/common';
import {
  StyledHeader, StyledNote, StyledPopUpWrapper, StyledInfoBlock, StyledDownload, StyledDevice, StyledDeviceBlock, StyledCloseIcon
} from './style';

interface SuccessPopUpProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  information: {
    account_server: string;
    account_number: string;
    password: string;
    trading_platform: string;
    download_center: {
      [key: string]: string;
    }
  } | null;
}

const SuccessPopUp = ({ openModal, setOpenModal, information }: SuccessPopUpProps): JSX.Element => {
  const { t } = useTranslation(['demoAccount', 'downloadCenter']);

  return (
    <StyledPopUpWrapper openModal={openModal} onClose={() => setOpenModal(false)}>
      <StyledHeader>
        <IconComponent name="DemoAccountCheck" aria-label="check icon" />
        <span aria-label="success">
          {t('success')}
          !
        </span>
      </StyledHeader>
      <StyledNote aria-label="description">
        {`${t('descriptionFirst')} `}
        {t('descriptionSec')}
      </StyledNote>
      <StyledInfoBlock>
        <span aria-label="server name title">{t('server')}</span>
        <span aria-label="server name">{information?.account_server}</span>
      </StyledInfoBlock>
      <StyledInfoBlock>
        <span aria-label="account number title">{t('number')}</span>
        <span aria-label="account number">{information?.account_number}</span>
      </StyledInfoBlock>
      <StyledInfoBlock>
        <span aria-label="password title">{t('password')}</span>
        <span aria-label="password">{information?.password}</span>
      </StyledInfoBlock>
      <StyledDownload aria-label="download platform">
        {`${t('download')} ${information?.trading_platform === TradingPlatform.LogixTrader
          ? t('acyTradingPlatform')
          : information?.trading_platform}`}
      </StyledDownload>
      <StyledDeviceBlock>
        {information?.download_center.desktop
          && (
          <StyledDevice
            onClick={() => information?.download_center.desktop && window.open(information?.download_center.desktop, '_blank', 'noopener,noreferrer')}
            aria-label="windows"
          >
            <IconComponent name="Windows" />
            <span>{t('windows')}</span>
          </StyledDevice>
          )}
        {information?.download_center.macos
            && (
              <StyledDevice
                onClick={() => information?.download_center.macos && window.open(information?.download_center.macos, '_blank', 'noopener,noreferrer')}
                aria-label="mac"
              >
                <IconComponent name="MacOS" />
                <span>{t('macOS', { ns: 'downloadCenter' })}</span>
              </StyledDevice>
            )}
        {information?.download_center.webtrader
          && (
            <StyledDevice
              onClick={() => information?.download_center.webtrader
                && window.open(information?.download_center.webtrader, '_blank', 'noopener,noreferrer')}
              aria-label="webtrader"
            >
              <IconComponent name="WebAsset" />
              <span>{t('webtrader')}</span>
            </StyledDevice>
          )}
        {information?.download_center.ios
          && (
          <StyledDevice
            onClick={() => information?.download_center.ios && window.open(information?.download_center.ios, '_blank', 'noopener,noreferrer')}
            aria-label="ios"
          >
            <IconComponent name="Phone" />
            <span>{t('iOS', { ns: 'downloadCenter' })}</span>
          </StyledDevice>
          )}
        {information?.download_center.android
          && (
          <StyledDevice
            onClick={() => information?.download_center.android && window.open(information?.download_center.android, '_blank', 'noopener,noreferrer')}
            aria-label="android"
          >
            <IconComponent name="Android" />
            <span>{t('android')}</span>
          </StyledDevice>
          )}
      </StyledDeviceBlock>
      <StyledCloseIcon onClick={() => setOpenModal(false)}>
        <IconComponent name="DemoAccountClose" aria-label="cross icon" />
      </StyledCloseIcon>
    </StyledPopUpWrapper>
  );
};

export default SuccessPopUp;
