/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import switchTimezoneCity from 'pages/Training/OnlineTraining/switchCityFunc';
import { appDayjs } from 'utils/appDayjs.util';
import {
  StyledWebinarItemContainer,
  StyledSubject,
  StyledSubjectTitle,
  StyledSubjectContent,
  StyledBlock,
  StyledButton
} from './style';

interface WebinarItem {
  title: string;
  subtitle: string;
  presenter: string | null;
  start_time: string;
  timezone: { utc: string; city: string; };
  end_time: string;
  id: number;
  enrolled: number;
}
interface WebinarItemProps {
   webinarItem: WebinarItem,
   onClick: () => void;
   rowIndex: number;
  }

function parseTimeFormat(time: string): string {
  const lastIndexOfClone = time.lastIndexOf(':');
  return time.slice(0, lastIndexOfClone);
}

const WebinarItemCard = ({ webinarItem, onClick, rowIndex }: WebinarItemProps): JSX.Element => {
  const {
    title, subtitle, start_time, end_time, timezone, enrolled, presenter
  } = webinarItem;
  const { t } = useTranslation('onlineTraining');
  const getCourseStatus = (startTime: string, isEnrolled: number): string => {
    if (appDayjs.utc().isAfter(startTime)) {
      return 'Started';
    }
    return isEnrolled ? 'Enrolled' : 'Click to enroll';
  };

  return (
    <StyledWebinarItemContainer>
      <StyledSubject>
        <StyledSubjectTitle aria-label={`webniar subject ${rowIndex}`}>{title}</StyledSubjectTitle>
        <StyledSubjectContent aria-label={`webniar subject content ${rowIndex}`}>
          --
          &nbsp;
          {subtitle}
        </StyledSubjectContent>
      </StyledSubject>
      <StyledBlock aria-label={`webniar presenter ${rowIndex}`}>{presenter}</StyledBlock>
      <StyledBlock aria-label={`webniar start time ${rowIndex}`}>{parseTimeFormat(start_time)}</StyledBlock>
      <StyledBlock aria-label={`webniar end time ${rowIndex}`}>{parseTimeFormat(end_time)}</StyledBlock>
      <StyledBlock aria-label={`webniar time zone ${rowIndex}`}>{`(${timezone.utc}) ${t(switchTimezoneCity(timezone.city))}`}</StyledBlock>
      <StyledBlock aria-label={`webniar status ${rowIndex}`}>
        <StyledButton onClick={onClick} className={getCourseStatus(start_time, enrolled)}>
          {t(getCourseStatus(start_time, enrolled))}
        </StyledButton>
      </StyledBlock>
    </StyledWebinarItemContainer>
  );
};

export default WebinarItemCard;
