import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { getAccounts } from 'api/v1/account';
import useSWR from 'swr';
import { AccountStatus, AccountType } from 'type/common';

export interface MemberData {
  name: string;
  role: string;
  permissions: string[];
  email: string;
  id: string;
}

export interface AccountData {
  name: string;
  type: AccountType;
  status: AccountStatus;
  regulation: string;
  id: string;
  traderId: string;
  members?: MemberData[];
  exceedRG227FailedAttempts: boolean;
}

type Context = {
  accountList: AccountData[];
  setAccountList: Dispatch<SetStateAction<AccountData[]>>;
  isLoading: boolean;
};

const AccountListContext = createContext<Context>({} as Context);

export const AccountListProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [accountList, setAccountList] = useState<AccountData[]>([]);
  const { data, isLoading } = useSWR(['/accounts', 'get'], getAccounts, { dedupingInterval: 2000, revalidateOnFocus: false });

  useEffect(() => {
    if (!isLoading && data?.data) {
      setAccountList(
        data.data.map((account: { [key: string]: string }) => ({
          name: account.name,
          type: account.type,
          status: account.status.replace(/^./, account.status[0]),
          regulation: account.regulation.toUpperCase(),
          id: account.id,
          traderId: account.traderId,
          ...(account.members && { members: account.members }),
          exceedRG227FailedAttempts: account.is_blocked_by_rg227
        }))
      );
    }
  }, [isLoading, data]);

  const value = useMemo(
    () => ({
      accountList,
      setAccountList,
      isLoading
    }),
    [accountList, isLoading]
  );

  return (
    <AccountListContext.Provider value={value}>
      {children}
    </AccountListContext.Provider>
  );
};

export const useAccountListState = (): Context => useContext(AccountListContext);
