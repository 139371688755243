import RegisterContainers from 'containers/RegisterFormContainers';
import { useAuth } from 'store/context/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'type/common';
import { StyledRegister } from './style';

const Register = ():JSX.Element => {
  const {
    accessToken
  } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken || localStorage.getItem('accessToken')) {
      navigate(RouterPath.Home);
    }
  }, [accessToken, navigate]);

  return (
    <StyledRegister>
      <RegisterContainers />
    </StyledRegister>

  );
};

export default Register;
