/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CONFIRM_ID_TIMEOUT, DEFAULT_TIMEOUT } from 'api/axios';
import { AxiosPromise } from 'axios';
import { CreateAccountSteps } from 'constant/createAccount';
import { DOMAIN_CONFIG } from 'constant/domainConfig';
import { RG227Test } from 'type/rg227';
import { handleSignupPageUrl, flatFormData } from 'utils/common';

export const createAccount = (form?: object, formStep?: string, currentStep?: string): AxiosPromise => axios({
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  url: '/accounts',
  data: {
    ...form,
    signup_page_url: handleSignupPageUrl(),
    demo_cookie_url: window.location.origin,
    current_step: currentStep,
    form_step: formStep
  }
});

export const updatePersonalAccount = (
  form: Record<string, any>,
  accountID: string,
  formStep: string,
  currentStep: string
): AxiosPromise => {
  const formData = new FormData();

  Object.keys(form).forEach((key: string) => {
    flatFormData(formData, key, form[key]);
  });

  formData.append('current_step', currentStep);
  formData.append('form_step', formStep);
  formData.append('_method', 'PUT');

  return axios({
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `/accounts/${accountID}`,
    data: formData,
    timeout: currentStep === CreateAccountSteps.ConfirmID ? CONFIRM_ID_TIMEOUT : DEFAULT_TIMEOUT
  });
};

export const updateCorporateAccount = (
  form: Record<string, any>,
  accountID: string,
  formStep: string,
  currentStep: string
): AxiosPromise => {
  const formData = new FormData();

  Object.keys(form).forEach((key: string) => {
    flatFormData(formData, key, form[key]);
  });

  formData.append('current_step', currentStep);
  formData.append('form_step', formStep);
  formData.append('signup_page_url', handleSignupPageUrl());
  formData.append('_method', 'PUT');

  return axios({
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `/accounts/${accountID}`,
    data: formData
  });
};

export const updateJointAccount = (
  form: Record<string, any>,
  accountID: string,
  formStep: string,
  currentStep: string
): AxiosPromise => {
  const formData = new FormData();

  Object.keys(form).forEach((key: string) => {
    flatFormData(formData, key, form[key]);
  });

  formData.append('current_step', currentStep);
  formData.append('form_step', formStep);
  formData.append('signup_page_url', handleSignupPageUrl());
  formData.append('_method', 'PUT');

  return axios({
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `/accounts/${accountID}`,
    data: formData
  });
};

export const updateCountry = ({ form, accountID }: {
  form: Record<string, any>,
  accountID: string | null
}): AxiosPromise => {
  const formData = new FormData();
  Object.keys(form).forEach((key: string) => {
    flatFormData(formData, key, form[key]);
  });
  formData.append('_method', 'PATCH');

  return axios({
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `/accounts/${accountID}`,
    data: formData
  });
};

export const getJumioInfo = (url: string, resourceAccountID: string, key?: number): AxiosPromise => axios({
  method: 'POST',
  url: '/transactions',
  data: {
    successUrl: DOMAIN_CONFIG.origin + url,
    resource_account_id: resourceAccountID,
    workflowKey: key
  }
});

export const getJumioDetail = (
  accountId: string,
  workflowExecutionId: string
): AxiosPromise => axios({
  method: 'GET',
  url: '/transactions',
  params: {
    accountId,
    workflowExecutionId
  }
});

export const getVeriffSession = (url: string, resourceAccountID: string): AxiosPromise => axios({
  method: 'POST',
  url: '/veriff-sessions',
  data: {
    successUrl: DOMAIN_CONFIG.origin + url,
    resource_account_id: resourceAccountID
  }
});

export const getVeriffData = (resourceAccountID: string): AxiosPromise => axios({
  method: 'GET',
  url: `/accounts/${resourceAccountID}/veriff-sessions`
});

export const getAccounts = (): AxiosPromise => axios({
  method: 'GET',
  url: '/accounts'
});

export const getTradingPreferencesFields = (country: string): AxiosPromise => axios({
  method: 'GET',
  url: '/trading-preferences',
  params: {
    country
  }
});

export const getEmploymentFields = (country: string): AxiosPromise => axios({
  method: 'GET',
  url: '/employment-fields',
  params: {
    country
  }
});

export const getPersonalDetailForm = (): AxiosPromise => axios({
  method: 'GET',
  url: '/personal-details'
});

export const getAccountHolderDetailForm = (): AxiosPromise => axios({
  method: 'GET',
  url: '/joint-account-holder-details'
});

export const getCountryState = (country: string): AxiosPromise => axios({
  method: 'GET',
  url: 'states',
  params: {
    country
  }
});

export const getTermsAndConditionsForm = (country: string): AxiosPromise => axios({
  method: 'GET',
  url: '/terms-conditions',
  params: {
    country
  }
});

export const getExperienceForm = (accountID: string): AxiosPromise<RG227Test[]> => axios({
  method: 'GET',
  url: `/accounts/${accountID}/rg227-tests`
});

export const createExperienceTest = (accountID: string): AxiosPromise => axios({
  method: 'POST',
  url: `/accounts/${accountID}/rg227-tests`
});

export const getExperiencePracticeForm = (): AxiosPromise => axios({
  method: 'GET',
  url: '/rg227-tests-practice'
});

export const getCompanyDetails = (): AxiosPromise => axios({
  method: 'GET',
  url: '/company-details'
});

export const getShareholdersAndDirectors = (): AxiosPromise => axios({
  method: 'GET',
  url: '/shareholder-details'
});

export const addShareholder = (accountID: string): AxiosPromise => axios({
  method: 'POST',
  url: `/accounts/${accountID}/shareholders`
});

export const deleteShareholder = (accountID: string, shareholderID: string): AxiosPromise => axios({
  method: 'DELETE',
  url: `/accounts/${accountID}/shareholders/${shareholderID}`
});

export const addDirector = (accountID: string): AxiosPromise => axios({
  method: 'POST',
  url: `/accounts/${accountID}/directors`
});

export const deleteDirector = (accountID: string, shareholderID: string): AxiosPromise => axios({
  method: 'DELETE',
  url: `/accounts/${accountID}/directors/${shareholderID}`
});

export const submitTestAnswer = (
  form: object,
  accountID: string,
  testID: string,
  entryURL: string
): AxiosPromise => axios({
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  url: `/accounts/${accountID}/rg227-tests/${testID}`,
  data: {
    _method: 'PUT',
    entry_url: entryURL,
    ...form
  }
});

export const getAccountPortalLoginURL = (accountId: string, nextPath?: string): AxiosPromise => axios({
  method: 'POST',
  url: '/accounts/login-url-cmp',
  data: {
    account_id: accountId,
    ...(nextPath && { next_path: nextPath })
  }
});

export const getIncompleteAccount = (): AxiosPromise => axios({
  method: 'GET',
  url: '/incomplete-accounts'
});

export const updateFormStep = (
  accountID: string,
  formStep: string,
  country?: string,
  isJumioCallback = 0
): AxiosPromise => axios({
  method: 'POST',
  url: `/accounts/${accountID}`,
  data: {
    form_step: formStep,
    _method: 'PATCH',
    signup_page_url: handleSignupPageUrl(),
    is_jumio_callback: isJumioCallback,
    country
  }
});

export const getAccountByID = (accountID: string): AxiosPromise => axios({
  method: 'GET',
  url: `/accounts/${accountID}`
});

export const getRejectMessages = (accountID: string): AxiosPromise => axios({
  method: 'GET',
  url: `/accounts/${accountID}/reviews`
});

export const resubmitAccount = (
  form: object,
  accountID: string,
  formStep: string,
  currentStep: string
): AxiosPromise => axios({
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  url: `/accounts/${accountID}/resubmit`,
  data: {
    ...form,
    current_step: currentStep,
    form_step: formStep,
    _method: 'PUT'
  }
});

export const getKeycloakTokenByLogixopanelToken = (token: string): AxiosPromise => axios({
  method: 'GET',
  url: `/correction/${token}`
});

export const addCorporateAccountMember = (
  accountID: string,
  form: { email: string, first_name: string, last_name: string }
): AxiosPromise => axios({
  method: 'POST',
  url: `/accounts/${accountID}/members`,
  data: {
    ...form
  }
});

export const deleteCorporateAccountMember = (
  accountID: string,
  memberID: string
): AxiosPromise => axios({
  method: 'DELETE',
  url: `/accounts/${accountID}/members/${memberID}`
});

export const sendResetPasswordEmailToCorporateAccountMember = (
  accountID: string,
  memberID: string
): AxiosPromise => axios({
  method: 'PUT',
  url: `/accounts/${accountID}/members/${memberID}/reset-password`
});

export const uploadDocument = (
  accountID: string,
  documentCategory: string,
  file: File,
  targetId: number | undefined,
  documentId: number | string | undefined
): AxiosPromise => axios({
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  timeout: 180_000, // 3 minutes
  url: `/accounts/${accountID}/documents`,
  data: {
    target_id: targetId,
    document_category: documentCategory,
    file,
    targetId,
    document_id: documentId
  }
});

export const deleteDocument = (
  accountID: string,
  documentId? : number
): AxiosPromise => axios({
  method: 'DELETE',
  url: `/accounts/${accountID}/documents/${documentId}`
});

export const getFileList = (
  accountID: string
): AxiosPromise => axios({
  method: 'GET',
  url: `/accounts/${accountID}/documents`
});
