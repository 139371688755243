import styled from 'styled-components';
import { device } from 'styles/deviceStyle';

export const StyledUploadFile = styled.div`
  display: flex;
  gap: 11px;
  * {
    font-family: 'Noto Sans';
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const StyledStatusImgWrap = styled.div`
  text-align: center;
`;

const handleStatus = (status: string): string => {
  if (status === 'UPLOADED') {
    return `
      background:
      linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #18C083;
      padding:10px 5px;
      border: solid #00B875 2px;`;
  }
  if (status === 'ERROR') {
    return `
      background: #FBD5D5;
      border: 1px solid #C63434;
    `;
  }
  if (status === 'DISABLED') {
    return `
      background: #EEEEEE;
    `;
  }
  return `
    border-radius:2px;
    `;
};

const handleCurrentColor = (status: string): string => {
  switch (status) {
    case 'UPLOADED':
      return 'color: #004a2f';
    case 'ERROR':
      return 'color: #F44336';
    default:
      return 'color: #5c5c5c';
  }
};

export const StyledStatusBlock = styled.div<{ status: string }>`
  padding-top: 10px;
  width: 84px;
  /* height: 64px; */
  height: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  ${(props) => handleStatus(props.status)}

  > svg {
    flex-shrink: 0;
    font-size: 24px;
    path {
      ${(props) => ((props.status === 'UPLOADED') ? 'fill: #00b875' : 'fill: #424242')};
    }
  }
`;

export const StyledStatusTxt = styled.div<{ status: string }>`
  padding-bottom: 8px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  ${(props) => handleCurrentColor(props.status)}
`;

export const StyledFileInfoWrap = styled.div`
  .upload-btn {
    min-width: 88px;
    height: 24px;
    background: #f6f6f6;
    border: solid #013b81 1px;
    border-radius: 4px;
    color: #013b81;
    font-size: 10px;
    font-family: 'Noto Sans';
    line-height: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-transform: Capitalize;
    
    &:hover {
      background: #013b81;
      color: #fff;
    }
    &:focus {
      background-color: #01254f;
      color: #fff;
    }
    &.Mui-disabled {
      border: gray;
      background-color: rgba(246, 246, 246, 1);
      color: rgba(0, 0, 0, 0.45);
    }

    .custom-circular-progress{
      width: 16px !important;
      height: 16px !important;
    }
  }
`;

export const StyledFileTitle = styled.div`
  font-weight: 600;
  color: #1c1c1c;
  font-size: 16px;
  line-height: 24px;

  @media ${device.mobileMaxWidth} {
    font-size: 14px;
  }
`;

export const StyledFileDesp = styled.div`
  color: #5c5c5c;
  font-weight: 400;
  font-size: 14px;
  margin: 8px 0;
  line-height: 24px;

  + div {
    display: flex;
    align-items: center;
  }

  @media ${device.mobileMaxWidth} {
    margin: 6px 0;
    font-size: 12px;
    + div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const StyledFileNameWrap = styled.div`
  font-weight: 400;
  font-size: 10px;
  margin: ${(props) => (props.theme.isRTL ? '0 10px 0 0' : '0 0 0 10px')};
  display: inline;
  line-height: 10px;
  color: #5c5c5c;
  word-break: break-all;

  @media ${device.mobileMaxWidth} {
    margin: 6px 0 0;
  }
`;

export const StyledSizeInfo = styled.div<{ status?: string }>`
  font-weight: 400;
  font-size: 10px;
  color: ${(props) => (props.status === 'error' ? '#C63434' : '#5c5c5c')};
  &.error {
    color: #c63434;
  }
`;

export const StyledCloseIcon = styled.span`
  cursor: pointer;
  margin: ${(props) => (props.theme.isRTL ? '0 10px 0 0' : '0 0 0 10px')};
  font-size: 4.38px;
  vertical-align: middle;
`;

export const StyledWarningWrapper = styled.div<{ markWarning?: boolean }>`
  margin-top: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: ${(props) => (props.markWarning ? '#F9F9F9' : 'rgba(249, 134, 0, 0.1)')};
`;

export const StyledWarningMsg = styled.div<{ markWarning?: boolean }>`
  color: ${(props) => (props.markWarning ? '#BBB' : '#F98600')};
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
`;
