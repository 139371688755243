import IconComponent from 'components/Icons';
import AppropriatenessAttention from 'assets/AppropriatenessAttention.png';
import {
  StyledActionGroup,
  StyledBox,
  StyledContent,
  StyledContentDescription,
  StyledContentTitle,
  StyledHeader,
  StyledPaper,
  StyledTitle,
  StyledImage
} from './style';

enum SeverityIcon {
  success = 'CheckCircleFilled',
  warning = 'WarningCircleFilled',
}

enum SeverityColor {
  success = '#00B875',
  warning = '#F98600',
}

const IMAGE_SRC = {
  AppropriatenessAttention
} as const;

interface BaseResponseMessagePaper {
  title: string;
  contentTitle: string;
  contentDescription: string | JSX.Element;
  actionButtonSlot?: JSX.Element;
}

interface SeverityResponseMessagePaper extends BaseResponseMessagePaper {
  severity: keyof typeof SeverityIcon;
  image?: never;
  imageAlt?: never;
}

interface ImageResponseMessagePaper extends BaseResponseMessagePaper {
  severity?: never;
  image: keyof typeof IMAGE_SRC;
  imageAlt: string;
}

type ResponseMessagePaper = SeverityResponseMessagePaper | ImageResponseMessagePaper;

const ResponseMessagePaper = ({
  severity,
  image,
  imageAlt,
  title,
  contentTitle,
  contentDescription,
  actionButtonSlot
}: ResponseMessagePaper): JSX.Element => {
  const iconElement = (): JSX.Element | null => {
    if (severity) {
      return <IconComponent aria-label="icon" name={SeverityIcon[severity]} color={SeverityColor[severity]} />;
    }

    if (image) {
      return <StyledImage aria-label="icon" src={IMAGE_SRC[image]} alt={imageAlt} />;
    }
    return null;
  };

  return (
    <StyledPaper>
      <StyledHeader>
        <StyledTitle aria-label="title">{title}</StyledTitle>
      </StyledHeader>
      <StyledContent>
        <StyledBox>
          {iconElement()}
          <StyledContentTitle aria-label="content title">{contentTitle}</StyledContentTitle>
          <StyledContentDescription aria-label="content desc">
            {contentDescription}
          </StyledContentDescription>
          {actionButtonSlot && (
          <StyledActionGroup aria-label="action button">{actionButtonSlot}</StyledActionGroup>
          )}
        </StyledBox>
      </StyledContent>
    </StyledPaper>
  );
};

export default ResponseMessagePaper;
