import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Table } from '@mui/material';
import IconComponent from 'components/Icons';
import { useAppDispatch } from 'store';
import ErrorHandler from 'utils/ErrorHandler';
import {
  getAccountPortalLoginURL,
  sendResetPasswordEmailToCorporateAccountMember
} from 'api/v1/account';
import { useLayoutControlState } from 'containers/AccountList/context/layoutControlContext';
import { useStagingDataState } from 'containers/AccountList/context/stagingDataContext';
import {
  AccountData
} from 'containers/AccountList/context/accountListContext';
import { AccountStatus, AccountType } from 'type/common';
import {
  StyledInnerTableBody,
  StyledInnerTableContainer,
  StyledInnerTableHead,
  StyledTableCell,
  StyledTableRow,
  StyledCollapseButton,
  StyledCollapseContainer,
  StyledCollapseTitle,
  StyledLabel,
  StyledActionButton,
  StyledActionButtonGroup,
  StyledIconButton
} from '../style';
import ActionMenu from '../../ActionMenu';

type RowProps = {
  rowData: AccountData;
  isOdd: boolean;
  index: number;
};

const Row = ({ rowData, isOdd, index }: RowProps): JSX.Element => {
  const { t } = useTranslation('accountList');
  const dispatch = useAppDispatch();
  const {
    setIsRemoveMemberDialogOpen,
    setIsBackdropSpinnerOpen,
    setIsInviteMemberDialogOpen,
    setIsTradingExperienceDialogOpen,
    setIsResponseMessageToastOpen,
    setResponseMessage
  } = useLayoutControlState();
  const { setMemberData, setAccountData } = useStagingDataState();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isAdminRole = rowData.members && rowData.members.length > 0;

  const handleLogin = (): void => {
    if (rowData.status === AccountStatus.Active || rowData.status === AccountStatus.Auto_Active) {
      setIsBackdropSpinnerOpen(true);
      const windowReference = window.open();
      ErrorHandler(getAccountPortalLoginURL(String(rowData.id)), dispatch)
        .then((res) => {
          if (res && res.status === 200) {
            if (windowReference) windowReference.location = res.data.login_url;
          }
        })
        .finally(() => {
          setTimeout(() => {
            setIsBackdropSpinnerOpen(false);
          }, 3000);
        });
    }
  };

  const handleSendResetPasswordEmail = (
    accountId: string,
    memberId: string
  ): void => {
    setIsBackdropSpinnerOpen(true);

    sendResetPasswordEmailToCorporateAccountMember(accountId, memberId)
      .then((res) => {
        if (res.status === 204) {
          setResponseMessage({
            type: 'success',
            title: t('emailSentSuccess'),
            content: t('emailSentSuccessMessage')
          });
        }
      })
      .catch((err) => {
        setResponseMessage({
          type: 'error',
          title: t('emailSentFailed'),
          content: err.response?.data?.message || err.message
        });
      })
      .finally(() => {
        setIsBackdropSpinnerOpen(false);
        setIsResponseMessageToastOpen(true);
      });
  };

  return (
    <>
      <StyledTableRow
        aria-label={`TableRow-${rowData.id}`}
        $hasStripeBackground={isOdd}
        $hasHoverBackground
      >
        <StyledTableCell>
          {rowData.type === AccountType.corporate
            && rowData.status === AccountStatus.Active
            && isAdminRole && (
              <StyledCollapseButton
                type="button"
                onClick={() => setIsCollapsed((prevState) => !prevState)}
                isActive={isCollapsed}
              >
                <IconComponent aria-label={`Down Arrow Icon ${index}`} name="DownArrow" />
              </StyledCollapseButton>
          )}
        </StyledTableCell>
        <StyledTableCell aria-label={`Account Name ${index}`}>{rowData.name}</StyledTableCell>
        <StyledTableCell style={{ textTransform: 'capitalize' }} aria-label={`Account Type ${index}`}>
          {rowData.type}
        </StyledTableCell>
        <StyledTableCell
          style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
          aria-label={`Account Status ${index}`}
        >
          {rowData.status === AccountStatus.Auto_Active ? AccountStatus.Active : rowData.status }
          {rowData.status === AccountStatus.Pending
            && rowData.exceedRG227FailedAttempts && (
              <StyledIconButton
                onClick={() => setIsTradingExperienceDialogOpen(true)}
              >
                <IconComponent
                  name="WarningCircle"
                  width="20"
                  height="20"
                  color="#F98600"
                />
              </StyledIconButton>
          )}
        </StyledTableCell>
        <StyledTableCell aria-label={`Account Regulation ${index}`}>{rowData.regulation}</StyledTableCell>
        <StyledTableCell>
          <StyledActionButtonGroup>
            <StyledActionButton
              onClick={handleLogin}
              isDisabled={rowData.status !== AccountStatus.Active && rowData.status !== AccountStatus.Auto_Active}
              aria-label={`Account Action ${index}`}
            >
              {t('login')}
            </StyledActionButton>
            {rowData.type === AccountType.corporate
              && rowData.status === AccountStatus.Active
              && isAdminRole && (
                <StyledActionButton
                  aria-label={`Add Member ${index}`}
                  onClick={() => {
                    setAccountData(rowData);
                    setIsInviteMemberDialogOpen(true);
                  }}
                >
                  {t('addMember')}
                </StyledActionButton>
            )}
          </StyledActionButtonGroup>
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
            <StyledCollapseContainer>
              <StyledCollapseTitle aria-label="Member List Title">
                {t('memberList')}
              </StyledCollapseTitle>
              <StyledInnerTableContainer>
                <Table>
                  <StyledInnerTableHead>
                    <StyledTableRow>
                      <StyledTableCell aria-label="Member List Table Head Cell Name">
                        {t('name')}
                      </StyledTableCell>
                      <StyledTableCell aria-label="Member List Table Head Cell Role">
                        {t('role')}
                      </StyledTableCell>
                      <StyledTableCell aria-label="Member List Table Head Cell Email">
                        {t('email')}
                      </StyledTableCell>
                      <StyledTableCell
                        aria-label="Member List Table Head Cell Action"
                        style={{ width: '100px' }}
                      >
                        {t('action')}
                      </StyledTableCell>
                    </StyledTableRow>
                  </StyledInnerTableHead>
                  <StyledInnerTableBody>
                    {rowData.members?.map((member, membersIndex) => (
                      <StyledTableRow key={member.id}>
                        <StyledTableCell aria-label={`Memeber Name ${membersIndex}`}>{member.name}</StyledTableCell>
                        <StyledTableCell>
                          <StyledLabel
                            isHighlighted={member.permissions.includes('admin')}
                            aria-label={`Memeber Role ${membersIndex}`}
                          >
                            {member.role}
                          </StyledLabel>
                        </StyledTableCell>
                        <StyledTableCell aria-label={`Memeber Email ${membersIndex}`}>{member.email}</StyledTableCell>
                        <StyledTableCell style={{ width: '100px' }}>
                          {!member.permissions.includes('admin') && (
                            <ActionMenu
                              onSendResetPasswordEmailButtonClick={() => {
                                handleSendResetPasswordEmail(
                                  rowData.id,
                                  member.id
                                );
                              }}
                              onRemoveButtonClick={() => {
                                setAccountData(rowData);
                                setMemberData(member);
                                setIsRemoveMemberDialogOpen(true);
                              }}
                            />
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </StyledInnerTableBody>
                </Table>
              </StyledInnerTableContainer>
            </StyledCollapseContainer>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default Row;
