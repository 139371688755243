import Button from 'components/Button';
import { useAppSelector, useAppDispatch } from 'store';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { backStep } from 'containers/CreateAccountForm/slices';
import { useCreateJointAccountFlow } from 'store/context/hooks';
import { useNavigate } from 'react-router';
import { updateFormStep } from 'api/v1/account';
import ErrorHandler from 'utils/ErrorHandler';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ResubmitFooter from 'containers/CreateJointAccountForm/components/ResubmitFooter';
import { RouterPath } from 'type/common';
import { CreateJointAccountSteps } from 'constant/createAccount';
import { StyledLButtonsWrap } from './style';

interface StepButtonsProps {
  isSubmitting?: boolean
}

const StepButtons = (props: StepButtonsProps):JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const currentStep = useAppSelector((state) => state.createAccountState.step);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { isSubmitting = false } = props;
  const { formState: { isValid }, getValues } = useFormContext();
  const {
    steps, formIsLoading, updateRejectStepValidStatus
  } = useCreateJointAccountFlow();
  const navigate = useNavigate();
  const isLastStep = currentStep === steps.length - 1;
  const isReject = searchParams.get('status') === 'Reject';
  const isShowAppropriatenessAttention = steps[currentStep] === CreateJointAccountSteps.JointAppropriateness
    ? !getValues('rg227_test_id')
    : false;

  const handleBack = ():void => {
    const accountID = localStorage.getItem('accountID');
    if (currentStep === 0) {
      navigate(RouterPath.createAccountSelectAccountType);
    } else {
      if (accountID) {
        ErrorHandler(updateFormStep(
          accountID,
          steps[currentStep - 1],
          getValues('country')
        ), dispatch);
      }
      dispatch(backStep());
    }
  };

  useEffect(() => {
    if (!isReject) return;
    updateRejectStepValidStatus(steps[currentStep], isValid);
  }, [currentStep, isReject, isValid, steps]);

  return (
    <StyledLButtonsWrap>
      <div>
        <Button
          className="back-button"
          onClick={handleBack}
        >
          {t('back')}
        </Button>
        <Button
          id={steps[currentStep]}
          className={cx('next-button', { hidden: isReject && isLastStep })}
          htmlType="submit"
          disabled={!isValid || formIsLoading || isSubmitting || isShowAppropriatenessAttention}
        >
          {isLastStep ? t('submit') : t('next')}
        </Button>
        {isReject && (<ResubmitFooter />)}
      </div>
    </StyledLButtonsWrap>
  );
};

export default StepButtons;
