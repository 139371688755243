import * as React from 'react';
import cx from 'classnames';
import { CommonProps } from 'type/component';
import { StyledButton, StyledLink } from './styled';

export interface ButtonProps extends CommonProps {
  id?: string;
  type?: 'primary' | 'default';
  htmlType?: 'button' | 'submit';
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    children, type = 'default', href, className, htmlType = 'button', disabled = false, ...other
  } = props;

  return href ? (
    <StyledLink
      href={href}
      className={cx('link', 'lp-btn', type, className)}
      {...other}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledButton
      type={htmlType}
      className={cx('button', 'lp-btn', type, className)}
      disabled={disabled}
      {...other}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
